import { Avatar } from '@mui/material'
import { Link } from 'react-router-dom'

const HeaderCardPost = ({ account }) => {
  const { username, id, avatar, address } = account || {}
  return (
    <header>
      <div className="p-4 flex text-left">
        <div className="flex grow-0 mr-2 items-center">
          <Link to={`/profile/${id}`}>
            <Avatar alt="avatar" src={avatar} />
          </Link>
        </div>
        <div className="flex items-start justify-center grow flex-col leading-4">
          <Link to={`/profile/${id}`}>
            <span className="text-4 font-semibold">{username}</span>
          </Link>
          <span className="text-[0.9rem] mt-1 text-[#808080]">{address}</span>
        </div>
      </div>
    </header>
  )
}

export default HeaderCardPost
