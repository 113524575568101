import { Route, Routes } from 'react-router-dom'
import Home from '../pages/Home/Home'
import MainLayout from '../components/MainLayout/MainLayout'
import LandingPage from '../pages/LandingPage/LandingPage'
import PostDetail from '../pages/PostDetail/PostDetail'
import Category from '../pages/Category/Category'
import UpPost from '../pages/UpPost/UpPost'
import Test from '../pages/Test/Test'
import Profile from '../pages/Profile/Profile'
import History from '../pages/History/History'

const RoutesPage = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/" element={<MainLayout />}>
          <Route path="home" element={<Home />} />
          <Route path="post/:id_post" element={<PostDetail />} />
          <Route path="category" element={<Category />} />
          <Route path="category/:id_category" element={<Category />} />
          <Route path="up-post" element={<UpPost />} />
          <Route path="profile/:id_profile" element={<Profile />} />
          <Route path="history" element={<History />} />
          <Route path="test" element={<Test />} />
        </Route>
      </Routes>
    </>
  )
}

export default RoutesPage
