import * as React from 'react'
import Header from './Header'
import { Outlet } from 'react-router-dom'

const MainLayout = ({}) => {
  return (
    <div className="body-container-color">
      <div className="w-full">
        <div className="container-app m-auto">
          <Header />
        </div>
      </div>
      <div className="w-full body-main-layout">
        <div className="container-app m-auto px-3">
          <Outlet />
        </div>
      </div>
    </div>
  )
}

export default MainLayout
