import { useParams } from 'react-router-dom'
import InfoProfile from './InfoProfile/InfoProfile'
import TabContent from './TabContent/TabContent'
import { useGetAccountQuery, useGetMeQuery } from '../../services/Account/accountAPI'
import { useEffect, useMemo } from 'react'
import LoadingComponent from '../../components/UI/LoadingComponent/LoadingComponent'
import { useAuth } from '../../hooks/useAuth'

const Profile = () => {
  const { id_profile } = useParams()
  const {
    data: account,
    refetch,
    isLoading,
  } = useGetAccountQuery(id_profile, { refetchOnMountOrArgChange: true })
  const { data: me } = useGetMeQuery(undefined, { refetchOnMountOrArgChange: true })
  const auth = useAuth()

  const { id: idAccount } = account?.result || {}
  const { id: idMe } = me?.result || {}
  const { isLogged } = auth

  const isMe = useMemo(() => {
    if (idAccount === idMe) return true
    return false
  }, [idAccount, idMe])

  useEffect(() => {
    if (id_profile) {
      refetch()
    }
  }, [id_profile, refetch])

  if (isLoading) {
    return <LoadingComponent />
  }

  return (
    <>
      <div className="pt-9 px-[60px] pb-5">
        <InfoProfile account={account} isLogged={isLogged} isMe={isMe} idMe={idMe} />
      </div>
      <div className="pb-5">
        <TabContent isMe={isMe} idAccount={idAccount} />
      </div>
    </>
  )
}

export default Profile
