import { Box, Typography } from '@mui/material'
import CategoryItem from '../../../components/common/CategoryItem/CategoryItem'
import { useGetListCategoryQuery } from '../../../services/Category/categoryAPI'

const SectionCategory = () => {
  const { data: categories } = useGetListCategoryQuery(undefined, {
    refetchOnMountOrArgChange: true,
  })

  return (
    <div className="pt-16">
      <Typography variant="h5" style={{ marginBottom: '32px' }}>
        Danh Mục
      </Typography>
      <Box
        sx={{
          position: 'relative',
          width: 'fit-content',
          display: 'grid',
          gridAutoFlow: 'column',
        }}
      >
        {(categories?.result || [])?.map((item, index) => {
          return <CategoryItem key={index} name={item.name} id={item.id} src={item.urlImage} />
        })}
      </Box>
    </div>
  )
}

export default SectionCategory
