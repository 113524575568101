import { Box, Button, Menu, MenuItem, Select, Tab, Tabs, Typography } from '@mui/material'
import { useState } from 'react'
import ItemPostProfile from '../ItemPostProfile/ItemPostProfile'
import ItemPostMange from '../ItemPostManage/ItemPostMange'
import CustomButton from '../../../components/UI/CustomButton/CustomButton'
import { useGetListPostByAccountQuery } from '../../../services/Post/postAPI'
import LoadingComponent from '../../../components/UI/LoadingComponent/LoadingComponent'

const TabPanel = (props) => {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ paddingTop: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

const TabContent = ({ isMe, idAccount }) => {
  const [value, setValue] = useState(0)
  const [filter, setFilter] = useState('All')

  const {
    data: postsAccount,
    isLoading,
    refetch,
  } = useGetListPostByAccountQuery(
    { id_account: idAccount, filter },
    {
      refetchOnMountOrArgChange: true,
    }
  )

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const handleChangeFilter = (event) => {
    setFilter(event.target.value)
  }

  return (
    <>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          centered
          variant="fullWidth"
        >
          <Tab label="Bài đăng" className="w-1/2" />
          {isMe && <Tab label="Quản lý bài đăng" className="w-1/2" />}
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <div className="grid grid-cols-3 gap-4 scroll-pb-4">
          {isLoading && <LoadingComponent />}
          {postsAccount?.result?.map((item) => {
            return <ItemPostProfile avatar={item?.imagesPost[0]?.url} idPost={item?.id} />
          })}
        </div>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <div className="pb-4 space-y-3">
          <div className="flex justify-end">
            <Select
              labelId="demo-select-small-label"
              id="demo-select-small"
              value={filter}
              label="Lọc theo người đặt"
              onChange={handleChangeFilter}
              size="small"
            >
              <MenuItem value="All">Tất cả</MenuItem>
              <MenuItem value="Booked">Có người đặt</MenuItem>
              <MenuItem value="No Book">Không ai đặt</MenuItem>
            </Select>
          </div>

          {isLoading && <LoadingComponent />}
          {postsAccount?.result?.map((item) => {
            return <ItemPostMange data={item} />
          })}
        </div>
      </TabPanel>
    </>
  )
}

export default TabContent
