import { Avatar } from '@mui/material'
import { useCallback, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'

function AvatarUpload({ getDataAvatar }) {
  const [avatarSrc, setAvatarSrc] = useState('')

  const onDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0]
      const reader = new FileReader()

      reader.onload = (event) => {
        setAvatarSrc(event.target.result)
        getDataAvatar(event.target.result)
      }

      reader.readAsDataURL(file)
    },
    [getDataAvatar]
  )

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: 'image/jpeg, image/png',
    maxFiles: 1,
  })

  const dropStyle = {
    backgroundColor: 'rgba(0,0,0,.04)',
    color: '#aaa',
  }

  return (
    <div {...getRootProps()} style={{ width: '130px', height: '130px' }}>
      <input {...getInputProps()} />
      {avatarSrc ? (
        <Avatar alt="Avatar" src={avatarSrc} style={{ width: '100%', height: '100%' }} />
      ) : (
        <div
          style={dropStyle}
          className="flex justify-center items-center flex-col w-full h-full border-black-dashed-2 rounded-[50%] p-5 text-gray-600 cursor-pointer"
        >
          {isDragActive ? (
            <p>Thả file ảnh để upload</p>
          ) : (
            <>
              <CloudUploadIcon />
              <p>Avatar</p>
            </>
          )}
        </div>
      )}
      <p className="flex justify-center text-4 text-gray-600 font-bold cursor-pointer">
        Thêm avatar
      </p>
    </div>
  )
}

export default AvatarUpload
