import { Chip, IconButton, Stack } from '@mui/material'
import AvatarDefault from '../../../assets/image/avatar-default.jpeg'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye'
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest'
import DeleteIcon from '@mui/icons-material/Delete'
import ModalPeopleBook from '../ModalPeopleBook/ModalPeopleBook'
import { useMemo, useState } from 'react'
import { API, POST_TYPE } from '../../../constant'
import dayjs from 'dayjs'
import ModalConfirm from '../ModalConfirm/ModalConfirm'
import { Link } from 'react-router-dom'
import ModalUpdateAddress from '../ModalUpdatePost/ModalUpdateAddress'
import ModalUpdateSchool from '../ModalUpdatePost/ModalUpdateSchool'

const ItemPostMange = ({ data }) => {
  const [openPopup, setOpenPopup] = useState(false)
  const [openPopupConfirm, setOpenPopupConfirm] = useState(false)
  const [openPopupSchool, setOpenPopupSchool] = useState(false)
  const [openPopupAddress, setOpenPopupAddress] = useState(false)

  const {
    id,
    title,
    status,
    totalBooked,
    postType,
    expirationDate,
    imagesPost,
    giverAddress,
    giverDistrict,
    giverProvince,
    category,
    descriptionCategory,
  } = data || {}

  const messageWhereGive = useMemo(() => {
    if (postType === POST_TYPE.SCHOOL) {
      return 'Nhận ở trường đại học sài gòn'
    } else if (postType === POST_TYPE.ADDRESS) {
      return 'Nhận tại địa chỉ người cho'
    }
  }, [postType])

  const handleOpenModalUpdate = () => {
    if (postType === POST_TYPE.ADDRESS) {
      setOpenPopupAddress(true)
    } else {
      setOpenPopupSchool(true)
    }
  }

  return (
    <div className="bg-white shadow-lg rounded-md">
      <div className="p-4 flex justify-between">
        <div className="flex">
          {/* iamge */}
          <Link to={`/post/${id}`}>
            <div className="image w-[130px] mr-4">
              <div className="pt-[100%] relative">
                <div className="absolute inset-0">
                  <div className="relative w-full h-full bg-transparent bg-gray-200 hover:bg-gray-100 transition duration-175 ease-in-out delay-0">
                    <picture className="absolute inset-0">
                      <img
                        className="w-full h-full rounded-md opacity-100 object-cover transition-opacity duration-350 ease-in-out delay-0"
                        src={imagesPost[0]?.url || AvatarDefault}
                        alt=""
                      />
                    </picture>
                  </div>
                </div>
              </div>
            </div>
          </Link>
          {/* infoPost */}
          <div className="flex flex-col	justify-between">
            <div>
              <Link to={`/post/${id}`}>
                <span className="text-4 font-semibold">{title}</span>
              </Link>
              <div className="text-[14px] flex items-center">
                <span>{category?.name}</span>
                {descriptionCategory && <span>・</span>}
                <span>{descriptionCategory}</span>
              </div>
              {postType === POST_TYPE.ADDRESS && (
                <p className="text-[14px] text-[#717171]">{`${giverAddress}, ${giverDistrict}, ${giverProvince}`}</p>
              )}
              <Stack className="mt-2" direction="row" spacing={1}>
                <Chip label={`${totalBooked} người đặt`} color="success" size="small" />
                <Chip label={status} color="primary" size="small" />
              </Stack>
            </div>
            <div>
              <span className="text-[#E55E55] mt-3 text-[12px] font-bold">{messageWhereGive}</span>
            </div>
          </div>
        </div>
        {/* button setting */}
        <div className="flex flex-col justify-between items-end">
          <div className="flex flex-col">
            <IconButton
              color="secondary"
              aria-label="add an alarm"
              onClick={() => setOpenPopup(true)}
            >
              <RemoveRedEyeIcon />
            </IconButton>
            <IconButton color="secondary" aria-label="add an alarm" onClick={handleOpenModalUpdate}>
              <SettingsSuggestIcon />
            </IconButton>
            <IconButton
              color="secondary"
              aria-label="add an alarm"
              onClick={() => {
                setOpenPopupConfirm(true)
              }}
            >
              <DeleteIcon />
            </IconButton>
          </div>
          <div>
            <span className="text-[#808080] mt-3 text-[12px] font-bold">{`Kết thúc: ${dayjs(
              expirationDate
            ).format('DD/MM/YYYY')}`}</span>
          </div>
        </div>
      </div>
      {openPopup && (
        <ModalPeopleBook open={openPopup} handleClose={() => setOpenPopup(false)} idPost={id} />
      )}
      {openPopupConfirm && (
        <ModalConfirm
          open={openPopupConfirm}
          handleClose={() => setOpenPopupConfirm(false)}
          idPost={id}
        />
      )}
      {openPopupAddress && (
        <ModalUpdateAddress
          open={openPopupAddress}
          handleClose={() => setOpenPopupAddress(false)}
          idPost={id}
        />
      )}
      {openPopupSchool && (
        <ModalUpdateSchool
          open={openPopupSchool}
          handleClose={() => setOpenPopupSchool(false)}
          idPost={id}
        />
      )}
    </div>
  )
}

export default ItemPostMange
