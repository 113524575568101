import { Box, FormControl, Modal, TextField } from '@mui/material'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useLoginMutation } from '../../../services/Auth/authAPI'
import { setCredentials } from '../../../store/reducer/authSlice'
import MyAlert from '../Alert/Alert'
import CustomButton from '../CustomButton/CustomButton'
import ModalRegister from './ModalRegister'
import { useGetMeQuery } from '../../../services/Account/accountAPI'
import { setMe } from '../../../store/reducer/accountSlice'

const style = {
  transform: 'translate(-50%, -50%)',
  width: 400,
  boxShadow: 24,
  borderRadius: '30px',
}

const ModalLogin = ({ open, handleClose }) => {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [openModalRegister, setOpenModalRegister] = useState(false)
  const [messageAlert, setMessageAlert] = useState('')
  const [typeAlert, setTypeAlert] = useState('')
  const [openAlert, setOpenAlert] = useState(false)

  const [login, { isLoading, isError, error: errorLogin }] = useLoginMutation()
  const { data: me, refetch } = useGetMeQuery(undefined, { refetchOnMountOrArgChange: true })
  const dispatch = useDispatch()

  useEffect(() => {
    if (me) {
      dispatch(setMe(me?.result))
    }
  }, [dispatch, me])

  const handleLogin = async () => {
    try {
      const action = await login({ username, password })
      if (action?.data?.meta?.message === 'Success') {
        dispatch(setCredentials(action?.data?.result))
        await refetch()
        setOpenAlert(true)
        setTypeAlert('success')
        setMessageAlert('Đăng nhập thành công')
        handleClose()
      }
    } catch (error) {}
  }

  const handleOpenModalRegister = useCallback(() => {
    setOpenModalRegister(true)
    handleClose()
  }, [handleClose])

  const handleCloseModalRegister = () => setOpenModalRegister(false)

  useEffect(() => {
    if (isError) {
      setOpenAlert(true)
      setTypeAlert('error')
      setMessageAlert(errorLogin?.data?.meta?.message)
    }
  }, [isError, errorLogin])

  return (
    <>
      <Modal
        keepMounted
        open={open}
        onClose={handleClose}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={style} className="absolute top-2/4	left-1/2 bg-white pb-3">
          <div className="flex justify-center font-bold text-[20px] mb-1 px-5 pt-1">Đăng nhập</div>
          <hr />
          <div className="px-5 pt-5">
            <FormControl className="w-full" size="small">
              <div className="mb-4">
                <TextField
                  className="text-color-landing-page"
                  placeholder="Email hoặc số điện thoại"
                  fullWidth
                  onChange={(event) => setUsername(event.target.value)}
                  size="small"
                />
              </div>
              <TextField
                type="password"
                className="text-color-landing-page"
                placeholder="Password"
                fullWidth
                size="small"
                onChange={(event) => setPassword(event.target.value)}
              />
              <div className="flex justify-center mt-5">
                <div className="mr-4">
                  <CustomButton
                    name="Đăng Nhập"
                    size="small"
                    isLoading={isLoading}
                    onClick={() => handleLogin()}
                  />
                </div>
                <div>
                  <CustomButton
                    name="Đăng Ký"
                    size="small"
                    color="default"
                    onClick={handleOpenModalRegister}
                  />
                </div>
              </div>
            </FormControl>
          </div>
        </Box>
      </Modal>
      <ModalRegister open={openModalRegister} handleClose={handleCloseModalRegister} />
      <MyAlert
        open={openAlert}
        type={typeAlert}
        message={messageAlert}
        handleClose={() => setOpenAlert(false)}
      />
    </>
  )
}

export default ModalLogin
