import { Alert, Snackbar } from '@mui/material'

const MyAlert = ({ open, handleClose, message = '', type = 'success' }) => {
  const duration = 3000

  return (
    <div>
      <Snackbar
        open={open}
        autoHideDuration={duration}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={handleClose} severity={type} sx={{ width: '100%' }}>
          {message}
        </Alert>
      </Snackbar>
    </div>
  )
}

export default MyAlert
