import { Box, CircularProgress } from '@mui/material'

const LoadingComponent = ({ size = 40 }) => {
  return (
    <>
      <Box
        sx={{ display: 'flex', justifyContent: 'center', height: '100%', alignContent: 'center' }}
      >
        <CircularProgress size={size} />
      </Box>
    </>
  )
}

export default LoadingComponent
