import { Avatar, Box, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { NOTIFICATION_TYPE } from '../../../constant'
import AvatarDefault from '../../../assets/image/avatar-default.jpeg'
import { useCallback, useMemo } from 'react'
import { useUpdateNotificationMutation } from '../../../services/Notification/notificationAPI'

const NotificationItem = ({ data }) => {
  const navigate = useNavigate()

  const { id: id_noti, fromAccount, type, urlAvatar, post, toAccountStatus } = data
  const [updateNotification] = useUpdateNotificationMutation()

  const messageNotification = {
    [NOTIFICATION_TYPE.HAS_BOOKER]: {
      content: `${fromAccount.fullName} đã đặt bài của bạn`,
    },
    [NOTIFICATION_TYPE.ACCEPTED_GIVE]: {
      content: `${fromAccount.fullName} đã cho bạn đồ`,
    },
    [NOTIFICATION_TYPE.FOLLOW_POSTED]: {
      content: `${fromAccount.fullName} đã thêm bài đăng mới`,
    },
    [NOTIFICATION_TYPE.POST_BY_REPORT]: {
      content: `Bạn đã bị báo cáo bài đăng`,
    },
  }

  const unRead = useMemo(() => {
    if (toAccountStatus === 'Chưa đọc') return true
    return false
  }, [toAccountStatus])

  const handleClickItem = useCallback(async () => {
    const res = await updateNotification(id_noti)

    if (res?.data?.meta?.message === 'Success') {
      navigate(`/post/${post?.id}`)
    }
  }, [navigate, post, id_noti, updateNotification])

  return (
    <Box sx={{ width: '400px' }}>
      <Box
        sx={{
          paddingX: 2,
          paddingY: 1,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          cursor: 'pointer',
        }}
        onClick={handleClickItem}
      >
        <div className="flex items-center">
          <Avatar alt="avatar" src={urlAvatar || AvatarDefault} className="mr-2" />
          <Typography variant="pCategory" className="text-center">
            {messageNotification[type].content}
          </Typography>
        </div>
        {unRead && <div class="w-3 h-3 bg-red-400 rounded-full"></div>}
      </Box>
    </Box>
  )
}

export default NotificationItem
