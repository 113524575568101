import CategoryIcon from '@mui/icons-material/Category'
import CloseIcon from '@mui/icons-material/Close'
import SchoolIcon from '@mui/icons-material/School'
import TuneIcon from '@mui/icons-material/Tune'
import {
  Box,
  Collapse,
  Drawer,
  FormControlLabel,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Radio,
  RadioGroup,
} from '@mui/material'
import { useMemo, useState } from 'react'
import CustomButton from '../../components/UI/CustomButton/CustomButton'
import PostItem from '../../components/common/PostItem/PostItem'
import { ExpandLess, ExpandMore, StarBorder } from '@mui/icons-material'
import { useGetListPostQuery } from '../../services/Post/postAPI'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { useParams } from 'react-router-dom'
import { useGetListCategoryQuery } from '../../services/Category/categoryAPI'
import { useGetListBranchSchoolQuery } from '../../services/BranchSchool/branchSchoolAPI'

const Category = () => {
  const { id_category } = useParams()

  const [openSidebar, setOpenSidebar] = useState(false)
  const [openCategory, setOpenCategory] = useState(true)
  const [openSchool, setOpenSchool] = useState(false)
  const [numberPage, setNumberPage] = useState(1)
  const [idCategory, setCategory] = useState(id_category)
  const [idSchool, setSchool] = useState('')

  const { data: posts } = useGetListPostQuery(
    { limit: 12, page: numberPage, categoryId: idCategory, branchSchoolId: idSchool },
    {
      refetchOnMountOrArgChange: true,
    }
  )
  const { data: categories } = useGetListCategoryQuery(undefined, {
    refetchOnMountOrArgChange: true,
  })
  const { data: branchSchools } = useGetListBranchSchoolQuery(undefined, {
    refetchOnMountOrArgChange: true,
  })

  const isShowMore = useMemo(() => {
    if (numberPage === posts?.result?.totalPages) return false
    return true
  }, [numberPage, posts])

  const handleShowMore = () => {
    setNumberPage(numberPage + 1)
  }

  const handleClickCategory = () => {
    setOpenCategory(!openCategory)
  }

  const handleClickSchool = () => {
    setOpenSchool(!openSchool)
  }

  const handleToggleSidebar = (value) => {
    setOpenSidebar(value)
  }

  const handleChangeCategory = (event) => {
    setCategory(event.target.value)
  }

  const handleChangeSchool = (event) => {
    setSchool(event.target.value)
  }

  const list = () => (
    <Box sx={{ width: 375 }} role="presentation">
      <div className="p-4 grid text-center" style={{ gridTemplateColumns: '1fr auto' }}>
        <div className="text-[20px] font-bold">Filters</div>
        <div
          className="cursor-pointer flex items-center"
          onClick={() => handleToggleSidebar(false)}
        >
          <CloseIcon />
        </div>
      </div>
      <List>
        <ListItem disablePadding>
          <ListItemButton onClick={handleClickCategory}>
            <ListItemIcon>
              <CategoryIcon />
            </ListItemIcon>
            <ListItemText primary="Danh mục" />
            {openCategory ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
        </ListItem>
        <Collapse in={openCategory} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue={id_category}
                name="radio-buttons-group"
                onChange={handleChangeCategory}
              >
                {(categories?.result || [])?.map((item, index) => {
                  return (
                    <div>
                      <FormControlLabel value={item?.id} control={<Radio />} label={item?.name} />
                    </div>
                  )
                })}
              </RadioGroup>
            </ListItem>
          </List>
        </Collapse>
        <ListItem disablePadding>
          <ListItemButton onClick={handleClickSchool}>
            <ListItemIcon>
              <SchoolIcon />
            </ListItemIcon>
            <ListItemText primary="Chi nhánh Đại học Sài Gòn" />
            {openSchool ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
        </ListItem>
        <Collapse in={openSchool} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                name="radio-buttons-group"
                onChange={handleChangeSchool}
              >
                {(branchSchools?.result || []).map((item, index) => {
                  return (
                    <div>
                      <FormControlLabel
                        key={index}
                        value={item?.id}
                        control={<Radio />}
                        label={item?.name}
                      />
                    </div>
                  )
                })}
              </RadioGroup>
            </ListItem>
          </List>
        </Collapse>
      </List>
    </Box>
  )

  return (
    <>
      <div className="py-6">
        <CustomButton
          name="Filters"
          color="default"
          startIcon={<TuneIcon />}
          onClick={() => handleToggleSidebar(true)}
        />
      </div>

      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: 'repeat(4, minmax(0px, 1fr))',
          gap: '40px 20px',
        }}
      >
        {(posts?.result?.posts || []).map((post, index) => {
          return <PostItem key={index} post={post} />
        })}
      </Box>
      {isShowMore && (
        <div className="flex flex-col items-center cursor-pointer mt-6" onClick={handleShowMore}>
          <p className="text-[#5579C6] font-bold">Hiển thị thêm</p>
          <KeyboardArrowDownIcon color="primary" />
        </div>
      )}
      <Drawer anchor="left" open={openSidebar} onClose={() => handleToggleSidebar(false)}>
        {list()}
      </Drawer>
    </>
  )
}

export default Category
