import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { API, API_ROOT, NOTIFICATION_API } from '../../constant'

export const notificationApi = createApi({
  reducerPath: NOTIFICATION_API,
  baseQuery: fetchBaseQuery({
    baseUrl: API_ROOT,
    prepareHeaders: (header, { getState }) => {
      const token = getState().auth.token
      if (token) {
        header.set('Authorization', `Bearer ${token}`)
      }
    },
  }),
  endpoints: (builder) => ({
    getListNotification: builder.query({
      query: () => ({
        url: `${API.NOTIFICATION.LIST}${API.ACCOUNT.ME}`,
      }),
    }),
    updateNotification: builder.mutation({
      query: (id_noti) => ({
        url: `${API.NOTIFICATION.LIST}/${id_noti}${API.ACCOUNT.ME}`,
        method: 'PATCH',
        body: {
          toAccountStatus: 'Đã đọc',
        },
      }),
    }),
  }),
})

export const { useGetListNotificationQuery, useUpdateNotificationMutation } = notificationApi
