import * as React from 'react'
import SectionCategory from './SectionCategory/SectionCategory'
import SectionPostTrending from './SectionPostTrending/SectionPostTrending'

const Home = ({}) => {
  return (
    <>
      <SectionCategory />
      <SectionPostTrending />
    </>
  )
}

export default Home
