import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { API, API_ROOT, USER_API } from '../../constant'

export const userApi = createApi({
  reducerPath: USER_API,
  baseQuery: fetchBaseQuery({
    baseUrl: API_ROOT,
    prepareHeaders: (header, { getState }) => {
      const token = getState().auth.token
      if (token) {
        header.set('Authorization', `Bearer ${token}`)
      }
    },
  }),
  endpoints: (builder) => ({
    followUser: builder.mutation({
      query: (id_account) => ({
        url: `${API.USER.FOLLOW}/${id_account}`,
        method: 'POST',
        body: {},
      }),
    }),

    unFollowUser: builder.mutation({
      query: (id_account) => ({
        url: `${API.USER.FOLLOW}/${id_account}`,
        method: 'DELETE',
      }),
    }),

    ratingForReceiver: builder.mutation({
      query: (body) => ({
        url: `${API.USER.RATING_RECEIVER}/${body?.id_account}/post/${body?.id_post}`,
        method: 'POST',
        body: {
          star: body?.star,
        },
      }),
    }),

    ratingForGiver: builder.mutation({
      query: (body) => ({
        url: `${API.USER.RATING_GIVER}/${body?.id_account}/post/${body?.id_post}`,
        method: 'POST',
        body: {
          star: body?.star,
        },
      }),
    }),

    getListRating: builder.query({
      query: (body) => ({
        url: `/user/accountEvaluation/${body?.evaluatingAccountId}/${body?.evaluatedAccountId}/${body?.bookScheduleId}`,
      }),
    }),
  }),
})

export const {
  useFollowUserMutation,
  useUnFollowUserMutation,
  useRatingForReceiverMutation,
  useRatingForGiverMutation,
  useGetListRatingQuery,
} = userApi
