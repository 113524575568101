import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { API, API_ROOT, BRANCH_SCHOOL_API } from '../../constant'

export const branchSchoolApi = createApi({
  reducerPath: BRANCH_SCHOOL_API,
  baseQuery: fetchBaseQuery({ baseUrl: API_ROOT }),
  endpoints: (builder) => ({
    getListBranchSchool: builder.query({
      query: () => ({
        url: API.BRANCH_SCHOOL.LIST,
      }),
    }),
  }),
})

export const { useGetListBranchSchoolQuery } = branchSchoolApi
