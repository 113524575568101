import { Avatar, Box, FormControl, IconButton, InputBase, Modal, TextField } from '@mui/material'
import CustomButton from '../CustomButton/CustomButton'
import { DropzoneArea } from 'mui-file-dropzone'
import { useEffect, useMemo, useRef, useState } from 'react'
import AvatarDefault from '../../../assets/image/avatar-default.jpeg'
import AvatarUpload from '../AvatarUpload/AvatarUpload'
import { regexPhoneNumber } from '../../../utils/regex'
import { useRegisterMutation } from '../../../services/Auth/authAPI'
import MyAlert from '../Alert/Alert'

const style = {
  transform: 'translate(-50%, -50%)',
  width: 800,
  boxShadow: 24,
  borderRadius: '30px',
}

const ModalRegister = ({ open, handleClose }) => {
  const [avatar, setAvatar] = useState('')
  const [username, setUsername] = useState('')
  const [phone, setPhone] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [address, setAddress] = useState('')
  const [city, setCity] = useState('')
  const [checkPhone, setCheckPhone] = useState(false)
  const [checkPassword, setCheckPassword] = useState(true)
  const [messageAlert, setMessageAlert] = useState('')
  const [typeAlert, setTypeAlert] = useState('')
  const [openAlert, setOpenAlert] = useState(false)

  const [register, { isLoading, isError, error: errorRegister }] = useRegisterMutation()

  const handleRegister = async () => {
    const checkRegexPhone = regexPhoneNumber(phone)

    if (checkRegexPhone) {
      setCheckPhone(false)
      if (password === confirmPassword) {
        setCheckPassword(true)
        const data = {
          username,
          password,
          firstName,
          lastName,
          phone,
          address,
          city,
          avatar:
            'https://www.google.com/url?sa=i&url=https%3A%2F%2Fbizflycloud.vn%2Ftin-tuc%2Fjavascript-la-gi-va-no-hoat-dong-nhu-the-nao-20181123142006163.htm&psig=AOvVaw2F5C9SygpUg_BcdEI1kBCE&ust=1684856570986000&source=images&cd=vfe&ved=0CBEQjRxqFwoTCMifgv2hif8CFQAAAAAdAAAAABAE',
        }

        const res = await register(data)

        if (res?.data?.meta?.message === 'Success') {
          setOpenAlert(true)
          setTypeAlert('success')
          setMessageAlert('Đăng ký thành công')
          handleClose()
        }
      } else {
        setCheckPassword(false)
      }
    } else {
      setCheckPhone(true)
    }
  }

  useEffect(() => {
    if (isError) {
      setOpenAlert(true)
      setTypeAlert('error')
      setMessageAlert(errorRegister?.data?.meta?.message)
    }
  }, [isError, errorRegister])

  return (
    <>
      <Modal
        keepMounted
        open={open}
        onClose={handleClose}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={style} className="absolute top-2/4	left-1/2 bg-white pb-3">
          <div className="flex justify-center font-bold text-[20px] mb-1 px-5 pt-1">Đăng Ký</div>
          <hr />
          <div className="px-5 pt-5">
            <form>
              <FormControl className="w-full" size="small">
                <div className="space-y-4">
                  <div className="w-full flex justify-center mb-12">
                    <AvatarUpload
                      getDataAvatar={(value) => {
                        setAvatar('value :>> ', value)
                      }}
                    />
                  </div>

                  <div className="flex justify-between">
                    <div className="mr-4 w-1/2">
                      <TextField
                        className="text-color-landing-page"
                        placeholder="Tên đăng nhập"
                        fullWidth
                        size="small"
                        required
                        onChange={(event) => setUsername(event.target.value)}
                      />
                    </div>
                    <div className="w-1/2">
                      <TextField
                        className="text-color-landing-page"
                        placeholder="Số điện thoại"
                        fullWidth
                        size="small"
                        required
                        onChange={(event) => setPhone(event.target.value)}
                      />
                    </div>
                  </div>
                  <div className="flex justify-between">
                    <div className="mr-4 w-1/2">
                      <TextField
                        className="text-color-landing-page"
                        placeholder="Họ"
                        fullWidth
                        size="small"
                        required
                        onChange={(event) => setFirstName(event.target.value)}
                      />
                    </div>
                    <div className="w-1/2">
                      <TextField
                        className="text-color-landing-page"
                        placeholder="Tên"
                        fullWidth
                        size="small"
                        required
                        onChange={(event) => setLastName(event.target.value)}
                      />
                    </div>
                  </div>

                  <div className="flex justify-between">
                    <div className="mr-4 w-1/2">
                      <TextField
                        type="password"
                        className="text-color-landing-page"
                        placeholder="Mật khẩu"
                        fullWidth
                        size="small"
                        required
                        onChange={(event) => setPassword(event.target.value)}
                      />
                    </div>
                    <div className="w-1/2">
                      <TextField
                        type="password"
                        className="text-color-landing-page"
                        placeholder="Nhập lại mật khẩu"
                        fullWidth
                        size="small"
                        required
                        onChange={(event) => setConfirmPassword(event.target.value)}
                      />
                    </div>
                  </div>

                  <div className="flex justify-between">
                    <div className="mr-4 w-2/3">
                      <TextField
                        className="text-color-landing-page"
                        placeholder="Đại chỉ"
                        fullWidth
                        size="small"
                        required
                        onChange={(event) => setAddress(event.target.value)}
                      />
                    </div>
                    <div className="w-1/3">
                      <TextField
                        className="text-color-landing-page"
                        placeholder="Thành phố"
                        fullWidth
                        size="small"
                        required
                        onChange={(event) => setCity(event.target.value)}
                      />
                    </div>
                  </div>
                </div>

                <div className="flex justify-center items-center flex-col mt-5">
                  {!checkPassword && password && confirmPassword && (
                    <p className="mb-4 text-rose-600">
                      Xác nhận mật khẩu và mật khẩu không giống nhau
                    </p>
                  )}
                  {checkPhone && phone && (
                    <p className="mb-4 text-rose-600">Số điện thoại sai định dạng</p>
                  )}

                  <div>
                    <CustomButton
                      type="submit"
                      name="Đăng Ký"
                      size="small"
                      color="primary"
                      isLoading={isLoading}
                      onClick={handleRegister}
                    />
                  </div>
                </div>
              </FormControl>
            </form>
          </div>
        </Box>
      </Modal>
      <MyAlert
        open={openAlert}
        type={typeAlert}
        message={messageAlert}
        handleClose={() => setOpenAlert(false)}
      />
    </>
  )
}

export default ModalRegister
