export const API_ROOT = 'https://api-exchange.vercel.app'

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  API_ROOT,
  ADDRESS: '/api/?depth=2',
  ACCOUNT: {
    ACCOUNT: '/accounts',
    ME: '/accounts/me',
    UPDATE: '/user/accounts/me',
    LIST_BOOKER: '/bookings',
  },
  USER: {
    FOLLOW: '/user/followAccount/accounts/me/followAccount',
    RATING_RECEIVER: '/user/accountEvaluation/accountReceiver',
    RATING_GIVER: '/user/accountEvaluation/accountGiver',
  },
  AUTH: {
    SIGN_IN: '/auth/user/signIn',
    SIGN_UP: '/auth/user/signUp',
  },
  POST: {
    LIST: '/posts',
    UP_POST: '/user/posts',
    UP_IMAGE: '/images',
    FREE_DAY: '/freeDaysToBook',
    BOOK: '/book',
    SHIP: 'shipFee/ghtk',
    BY_ACCOUNT: '/posts/accounts',
    ACCEPT: '/acceptBook',
    HISTORY: '/historyBooked',
    CANCEL: '/deleteBookSchedule',
    LIKE: '/likePost',
    UN_LIKE: '/unlikePost',
    SAVE: '/savePost',
    UN_SAVE: '/unsavedPost',
    REOPEN: '/reopen',
  },
  BRANCH_SCHOOL: {
    LIST: '/branchesSchool',
  },
  CATEGORY: {
    LIST: '/categories',
  },
  NOTIFICATION: {
    LIST: 'user/notifications',
  },
  REPORT: {
    LIST: '/reportType',
    UP_REPORT: 'user/reportPost/posts',
  },
}
