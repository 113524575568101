import { Link } from 'react-router-dom'
import AvatarDefault from '../../../assets/image/avatar-default.jpeg'

const ItemPostProfile = ({ avatar, idPost }) => {
  return (
    <>
      <Link to={`/post/${idPost}`}>
        <div className="pt-[100%] relative">
          <div className="absolute inset-0">
            <div className="relative w-full h-full bg-transparent bg-gray-200 hover:bg-gray-100 transition duration-175 ease-in-out delay-0">
              <picture className="absolute inset-0">
                <img
                  className="w-full h-full opacity-100 object-cover transition-opacity duration-350 ease-in-out delay-0"
                  src={avatar || AvatarDefault}
                  alt=""
                />
              </picture>
            </div>
          </div>
        </div>
      </Link>
    </>
  )
}

export default ItemPostProfile
