import { Box, FormControl, InputLabel, MenuItem, Modal, Select, TextField } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import MyAlert from '../../../components/UI/Alert/Alert'
import CustomButton from '../../../components/UI/CustomButton/CustomButton'
import { useGetListCategoryQuery } from '../../../services/Category/categoryAPI'
import {
  useGetListPostByIdQuery,
  useReopenPostMutation,
  useUpdatePostMutation,
} from '../../../services/Post/postAPI'

const style = {
  transform: 'translate(-50%, -50%)',
  width: 800,
  boxShadow: 24,
  borderRadius: '30px',
}

const ModalUpdateSchool = ({ open, handleClose, idPost }) => {
  const [title, setTitle] = useState('')
  const [dateEnd, setDateEnd] = useState('')
  const [category, setCategory] = useState('')
  const [desCategory, setDesCategory] = useState('')
  const [description, setDescription] = useState('')
  const [messageAlert, setMessageAlert] = useState('')
  const [typeAlert, setTypeAlert] = useState('')
  const [openAlert, setOpenAlert] = useState(false)

  const { data: categories } = useGetListCategoryQuery(undefined, {
    refetchOnMountOrArgChange: true,
  })
  const { data, isLoading } = useGetListPostByIdQuery(idPost, { refetchOnMountOrArgChange: true })
  const [reopenPost, { isLoading: loadingReopen, isError, error }] = useReopenPostMutation()
  const [updatePost, { isLoading: loadingUpdate, isError: isErrorUpdate, error: updateError }] =
    useUpdatePostMutation()

  const handleReopenPost = async () => {
    const res = await reopenPost(idPost)

    if (res?.data?.meta?.message === 'Success') {
      setOpenAlert(true)
      setTypeAlert('success')
      setMessageAlert('Đăng bài lại thành công')
      handleClose()
    }
  }

  const handleUpdatePost = async () => {
    const bodyDate = dayjs(dateEnd).format('DD/MM/YYYY')
    const data = {
      title: title,
      expirationDate: dayjs(bodyDate, 'DD/MM/YYYY').format('YYYY-MM-DD[T]HH:mm:ss[Z]'),
      categoryId: Number(category),
      descriptionCategory: desCategory,
      description: description,
      postType: 'Tại trường',
    }

    const res = await updatePost({ data, id_post: idPost })

    if (res?.data?.meta?.message === 'Success') {
      setOpenAlert(true)
      setTypeAlert('success')
      setMessageAlert('Cập nhập bài đăng thành công')
      handleClose()
    }
  }

  useEffect(() => {
    setTitle(data?.result?.title)
    setDateEnd(data?.result?.expirationDate)
    setCategory(data?.result?.category?.id)
    setDesCategory(data?.result?.descriptionCategory)
    setDescription(data?.result?.description)
  }, [data])

  useEffect(() => {
    if (isError || isErrorUpdate) {
      setOpenAlert(true)
      setTypeAlert('error')
      setMessageAlert((error || updateError)?.data?.meta?.message)
    }
  }, [isError, error, isErrorUpdate, updateError])

  return (
    <>
      <Modal
        keepMounted
        open={open}
        onClose={handleClose}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={style} className="absolute top-2/4	left-1/2 bg-white pb-3">
          <div className="flex justify-center font-bold text-[20px] mb-1 px-5 pt-1">
            Chỉnh sửa thông tin bài đăng
          </div>
          <hr className="mb-4" />
          <div className="px-5 py-5">
            <form className="space-y-4">
              {/* info post */}
              <div className="flex justify-between w-full">
                <div className="w-1/2 mr-10">
                  <TextField
                    label="Tiêu đề"
                    onChange={(event) => setTitle(event.target.value)}
                    required
                    fullWidth
                    size="small"
                    value={title}
                  />
                </div>
                <div className="w-1/2">
                  <DatePicker
                    slotProps={{
                      textField: {
                        size: 'small',
                        fullWidth: true,
                        label: 'Ngày Kết Thúc',
                        required: true,
                      },
                    }}
                    value={dayjs(dateEnd, 'YYYY-MM-DD')}
                    onChange={(event) => {
                      setDateEnd(event.format('DD/MM/YYYY'))
                    }}
                  />
                </div>
              </div>

              <div className="flex justify-between w-full">
                <div className="w-1/2 mr-10">
                  <FormControl fullWidth size="small" required>
                    <InputLabel id="demo-simple-select-label">Danh mục</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      onChange={(event) => {
                        setCategory(event.target.value)
                      }}
                      value={category}
                    >
                      {(categories?.result || [])?.map((category, index) => {
                        return (
                          <MenuItem key={index} value={category.id}>
                            {category.name}
                          </MenuItem>
                        )
                      })}
                    </Select>
                  </FormControl>
                </div>
                <div className="w-1/2">
                  <TextField
                    label="Mô tả danh mục"
                    onChange={(event) => setDesCategory(event.target.value)}
                    fullWidth
                    size="small"
                    value={desCategory}
                  />
                </div>
              </div>

              <TextField
                label="Mô tả chi tiết"
                multiline
                rows={5}
                variant="outlined"
                onChange={(event) => setDescription(event.target.value)}
                fullWidth
                value={description}
              />
              <div className="flex justify-center space-x-4">
                <CustomButton
                  size="small"
                  color="warning"
                  name="Đăng lại bài đăng"
                  onClick={handleReopenPost}
                />
                <CustomButton
                  name="Hủy"
                  size="small"
                  color="default"
                  onClick={handleClose}
                  isLoading={loadingReopen}
                />
                <CustomButton
                  name="Xác nhận"
                  size="small"
                  color="primary"
                  isLoading={loadingUpdate}
                  onClick={handleUpdatePost}
                />
              </div>
            </form>
          </div>
        </Box>
      </Modal>
      <MyAlert
        open={openAlert}
        type={typeAlert}
        message={messageAlert}
        handleClose={() => setOpenAlert(false)}
      />
    </>
  )
}

export default ModalUpdateSchool
