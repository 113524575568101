import { Box, Typography } from '@mui/material'
import PostItem from '../../../components/common/PostItem/PostItem'
import { useGetListPostQuery } from '../../../services/Post/postAPI'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { useMemo, useState } from 'react'

const SectionPostTrending = () => {
  const [numberPage, setNumberPage] = useState(1)

  const { data: posts } = useGetListPostQuery(
    { limit: 12, page: numberPage, sort: 'Lasted' },
    {
      refetchOnMountOrArgChange: true,
    }
  )
  const isShowMore = useMemo(() => {
    if (numberPage === posts?.result?.totalPages) return false
    return true
  }, [numberPage, posts])

  const handleShowMore = () => {
    setNumberPage(numberPage + 1)
  }

  return (
    <>
      <div className="py-24">
        <Typography variant="h5" style={{ marginBottom: '32px' }}>
          Phổ biến
        </Typography>
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: 'repeat(4, minmax(0px, 1fr))',
            gap: '40px 20px',
          }}
        >
          {(posts?.result?.posts || []).map((post, index) => {
            return <PostItem key={index} post={post} />
          })}
        </Box>
        {isShowMore && (
          <div className="flex flex-col items-center cursor-pointer mt-6" onClick={handleShowMore}>
            <p className="text-[#5579C6] font-bold">Hiển thị thêm</p>
            <KeyboardArrowDownIcon color="primary" />
          </div>
        )}
      </div>
    </>
  )
}

export default SectionPostTrending
