export const WEEK_BOOK = [
  {
    id: 'Thứ 2',
    text: 'Thứ 2',
    value: false,
    time: [
      {
        id: '8h40 - 9h00',
        text: '8:40 AM - 9:00 AM (Giải lao sáng)',
        value: false,
      },
      {
        id: '11h30 - 13h00',
        text: '11:30 AM - 13:00 PM (Nghỉ trưa)',
        value: false,
      },
      {
        id: '14h40 - 15h00',
        text: '14:40 AM - 15:00 PM (Giải lao chiều)',
        value: false,
      },
    ],
  },
  {
    id: 'Thứ 3',
    text: 'Thứ 3',
    value: false,
    time: [
      {
        id: '8h40 - 9h00',
        text: '8:40 AM - 9:00 AM (Giải lao sáng)',
        value: false,
      },
      {
        id: '11h30 - 13h00',
        text: '11:30 AM - 13:00 PM (Nghỉ trưa)',
        value: false,
      },
      {
        id: '14h40 - 15h00',
        text: '14:40 AM - 15:00 PM (Giải lao chiều)',
        value: false,
      },
    ],
  },
  {
    id: 'Thứ 4',
    text: 'Thứ 4',
    value: false,
    time: [
      {
        id: '8h40 - 9h00',
        text: '8:40 AM - 9:00 AM (Giải lao sáng)',
        value: false,
      },
      {
        id: '11h30 - 13h00',
        text: '11:30 AM - 13:00 PM (Nghỉ trưa)',
        value: false,
      },
      {
        id: '14h40 - 15h00',
        text: '14:40 AM - 15:00 PM (Giải lao chiều)',
        value: false,
      },
    ],
  },
  {
    id: 'Thứ 5',
    text: 'Thứ 5',
    value: false,
    time: [
      {
        id: '8h40 - 9h00',
        text: '8:40 AM - 9:00 AM (Giải lao sáng)',
        value: false,
      },
      {
        id: '11h30 - 13h00',
        text: '11:30 AM - 13:00 PM (Nghỉ trưa)',
        value: false,
      },
      {
        id: '14h40 - 15h00',
        text: '14:40 AM - 15:00 PM (Giải lao chiều)',
        value: false,
      },
    ],
  },
  {
    id: 'Thứ 6',
    text: 'Thứ 6',
    value: false,
    time: [
      {
        id: '8h40 - 9h00',
        text: '8:40 AM - 9:00 AM (Giải lao sáng)',
        value: false,
      },
      {
        id: '11h30 - 13h00',
        text: '11:30 AM - 13:00 PM (Nghỉ trưa)',
        value: false,
      },
      {
        id: '14h40 - 15h00',
        text: '14:40 AM - 15:00 PM (Giải lao chiều)',
        value: false,
      },
    ],
  },
  {
    id: 'Thứ 7',
    text: 'Thứ 7',
    value: false,
    time: [
      {
        id: '8h40 - 9h00',
        text: '8:40 AM - 9:00 AM (Giải lao sáng)',
        value: false,
      },
      {
        id: '11h30 - 13h00',
        text: '11:30 AM - 13:00 PM (Nghỉ trưa)',
        value: false,
      },
      {
        id: '14h40 - 15h00',
        text: '14:40 AM - 15:00 PM (Giải lao chiều)',
        value: false,
      },
    ],
  },
]
