import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest'
import { Avatar, IconButton, Typography } from '@mui/material'
import AvatarDefault from '../../../assets/image/avatar-default.jpeg'
import CustomButton from '../../../components/UI/CustomButton/CustomButton'
import { useEffect, useState } from 'react'
import ModalSettingAccount from '../ModalSettingAccount/ModalSettingAccount'
import numeral from 'numeral'
import { useFollowUserMutation, useUnFollowUserMutation } from '../../../services/User/userAPI'
import MyAlert from '../../../components/UI/Alert/Alert'
import GradeIcon from '@mui/icons-material/Grade'

const InfoProfile = ({ account, isLogged, isMe, idMe }) => {
  const [showSetting, setShowSetting] = useState(false)
  const [isToggleFollow, setIsToggleFollow] = useState(false)
  const [messageAlert, setMessageAlert] = useState('')
  const [typeAlert, setTypeAlert] = useState('')
  const [openAlert, setOpenAlert] = useState(false)

  const [followUser, { isLoading: followLoading }] = useFollowUserMutation()
  const [unFollowUser, { isLoading: unFollowLoading }] = useUnFollowUserMutation()

  const {
    id,
    fullName,
    address,
    urlAvatar,
    username,
    followersAccount,
    followingAccount,
    posts,
    points,
    reputation,
    evaluatedAccounts,
  } = account?.result || {}

  const isFollowing = followersAccount.map((follower) => follower?.account?.id).includes(idMe)

  const numberPosts = posts.length
  const numberFollowers = followersAccount.length
  const numberFollowing = followingAccount.length

  const handleShowPopupSetting = () => {
    setShowSetting(true)
  }

  const handleFollowUser = async () => {
    if (!isToggleFollow) {
      const res = await followUser(id)

      if (res?.data?.meta?.message === 'Success') {
        setIsToggleFollow(true)
        setOpenAlert(true)
        setTypeAlert('success')
        setMessageAlert('Theo dõi thành công!')
      }
    } else {
      const res = await unFollowUser(id)

      if (res?.data?.meta?.message === 'Success') {
        setIsToggleFollow(false)
        setOpenAlert(true)
        setTypeAlert('success')
        setMessageAlert('Bỏ theo dõi thành công!')
      }
    }
  }

  useEffect(() => {
    if (isFollowing) setIsToggleFollow(isFollowing)
  }, [isFollowing])

  return (
    <>
      <div className="flex justify-between">
        <div className="flex">
          <Avatar
            alt="avatar"
            src={urlAvatar || AvatarDefault}
            sx={{ width: 190, height: 190, marginRight: '60px' }}
          />

          <div className="info">
            <div>
              <Typography variant="h1">@{username}</Typography>
            </div>
            <p className="text-[14px] text-[#717171]">{address}</p>
            <div className="my-4 flex">
              <div className="w-max text-[16px] mr-4">
                <p className="font-bold flex justify-center">{numeral(points).format('0.00')}</p>
                <p className="text-[#717171]">điểm</p>
              </div>
              <div className="w-max text-[16px] mr-4">
                <p className="font-bold flex justify-center">{numberPosts}</p>
                <p className="text-[#717171]">bài viết</p>
              </div>
              <div className="w-max text-[16px] mr-4">
                <p className="font-bold flex justify-center">{numberFollowers}</p>
                <p className="text-[#717171]">người theo dõi</p>
              </div>
              <div className="w-max text-[16px]">
                <p className="font-bold flex justify-center">{numberFollowing}</p>
                <p className="text-[#717171]">đang theo dõi</p>
              </div>
            </div>
            <div className="flex">
              {!isMe && isLogged && (
                <div className="mr-4">
                  <CustomButton
                    name={isToggleFollow ? 'Đang theo dõi' : 'Theo dõi'}
                    size="small"
                    onClick={handleFollowUser}
                    isLoading={followLoading || unFollowLoading}
                  />
                </div>
              )}
              <CustomButton
                name={`${numeral(reputation).format('0.0')} (${
                  evaluatedAccounts?.length
                } đánh giá)`}
                size="small"
                startIcon={<GradeIcon />}
                color="default"
                // onClick={handleFollowUser}
                // isLoading={followLoading || unFollowLoading}
              />
            </div>
          </div>
        </div>
        {isLogged && isMe && (
          <div>
            <IconButton
              color="secondary"
              aria-label="add an alarm"
              onClick={handleShowPopupSetting}
            >
              <SettingsSuggestIcon />
            </IconButton>
          </div>
        )}
      </div>

      <div className="mt-4">
        <p className="font-medium mb-2">{fullName}</p>
        <p className="text-[14px] text-[#717171]">Hãy theo dõi mình để có nhận quà thêm nhé!</p>
      </div>
      {isLogged && isMe && (
        <ModalSettingAccount open={showSetting} handleClose={() => setShowSetting(false)} />
      )}
      <MyAlert
        open={openAlert}
        type={typeAlert}
        message={messageAlert}
        handleClose={() => setOpenAlert(false)}
      />
    </>
  )
}

export default InfoProfile
