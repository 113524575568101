import { createSlice } from '@reduxjs/toolkit'

const initialState = { id: null, role: '' }

const slice = createSlice({
  name: 'accountSlice',
  initialState,
  reducers: {
    setMe: (state, { payload: { id, role } }) => {
      state.id = id
      state.role = role
    },
  },
})

export const { setMe } = slice.actions

export default slice.reducer
