import { Box, Typography } from '@mui/material'
import { Link } from 'react-router-dom'

const CategoryItem = ({ name = '', src = '', id }) => {
  return (
    <Link to={`/category/${id}`} className="mr-5">
      <Box
        sx={{
          position: 'relative',
          backgroundColor: 'white',
          borderRadius: '20px',
          boxShadow: 'rgba(0, 0, 0, 0.03) 0px 1px 2px 0px',
          minWidth: '117px',
          minHeight: '133px',
          aspectRatio: '3 / 4',
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            inset: '0px',
            display: 'grid',
            gridTemplateRows: '50px 30px',
            justifyItems: 'center',
            alignContent: 'center',
            width: '100%',
            padding: '0px 20px',
            minWidth: '100px',
          }}
        >
          <img className="h-10" src={src} alt="" />
          <Typography variant="pCategory" className="text-center">
            {name}
          </Typography>
        </Box>
      </Box>
    </Link>
  )
}

export default CategoryItem
