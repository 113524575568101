import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { API_ROOT, API, POST_API } from '../../constant'

export const postApi = createApi({
  reducerPath: POST_API,
  baseQuery: fetchBaseQuery({
    baseUrl: API_ROOT,
    prepareHeaders: (header, { getState }) => {
      const token = getState().auth.token
      if (token) {
        header.set('Authorization', `Bearer ${token}`)
      }
    },
  }),

  endpoints: (builder) => ({
    getListPost: builder.query({
      query: (params) => ({
        url: `${API.POST.LIST}?${new URLSearchParams(params).toString()}`,
      }),
    }),

    getListPostById: builder.query({
      query: (id) => ({
        url: `${API.POST.LIST}/${id}`,
      }),
    }),

    getListPostByAccount: builder.query({
      query: (body) => ({
        url: `${API.POST.BY_ACCOUNT}/${body?.id_account}?filter=${body.filter}`,
      }),
    }),

    upPost: builder.mutation({
      query: (body) => ({
        url: API.POST.UP_POST,
        method: 'POST',
        body,
      }),
    }),

    upPostImage: builder.mutation({
      query: (body) => {
        const { data, id_post } = body

        const formData = new FormData()
        data.forEach((file) => {
          formData.append(`files`, file)
        })

        return {
          url: `${API.POST.UP_POST}/${id_post}${API.POST.UP_IMAGE}`,
          method: 'POST',
          body: formData,
        }
      },
    }),

    getFreeDays: builder.query({
      query: (id_post) => ({
        url: `post/${id_post}${API.POST.FREE_DAY}`,
      }),
    }),

    bookPost: builder.mutation({
      query: (body) => ({
        url: `${API.POST.UP_POST}/${body.id_post}${API.POST.BOOK}`,
        method: 'POST',
        body: body.data,
      }),
    }),

    shipperMoney: builder.mutation({
      query: (body) => ({
        url: API.POST.SHIP,
        method: 'POST',
        body,
      }),
    }),

    acceptBooker: builder.mutation({
      query: (body) => ({
        url: `${API.POST.UP_POST}/${body?.id_post}${API.ACCOUNT.ACCOUNT}/${body?.id_account}${API.POST.ACCEPT}`,
        method: 'POST',
        body: {},
      }),
    }),

    getPostHistory: builder.query({
      query: () => ({
        url: `${API.POST.UP_POST}/account/me${API.POST.HISTORY}`,
      }),
    }),

    cancelBook: builder.mutation({
      query: (id_post) => ({
        url: `${API.POST.UP_POST}/${id_post}/account/me${API.POST.CANCEL}`,
        method: 'DELETE',
      }),
    }),

    likePost: builder.mutation({
      query: (id_post) => ({
        url: `${API.POST.UP_POST}/${id_post}${API.ACCOUNT.ME}${API.POST.LIKE}`,
        method: 'POST',
        body: {},
      }),
    }),

    deleteLikePost: builder.mutation({
      query: (id_post) => ({
        url: `${API.POST.UP_POST}/${id_post}${API.ACCOUNT.ME}${API.POST.UN_LIKE}`,
        method: 'DELETE',
      }),
    }),

    savePost: builder.mutation({
      query: (id_post) => ({
        url: `${API.POST.UP_POST}/${id_post}${API.ACCOUNT.ME}${API.POST.SAVE}`,
        method: 'POST',
        body: {},
      }),
    }),

    deleteSavePost: builder.mutation({
      query: (id_post) => ({
        url: `${API.POST.UP_POST}/${id_post}${API.ACCOUNT.ME}${API.POST.UN_SAVE}`,
        method: 'DELETE',
      }),
    }),

    deletePost: builder.mutation({
      query: (id_post) => ({
        url: `/user/posts/${id_post}/account/me`,
        method: 'DELETE',
      }),
    }),

    reopenPost: builder.mutation({
      query: (id_post) => ({
        url: `${API.POST.UP_POST}/${id_post}${API.POST.REOPEN}`,
        method: 'POST',
        body: {},
      }),
    }),

    updatePost: builder.mutation({
      query: (body) => ({
        url: `${API.POST.UP_POST}/${body?.id_post}/account/me`,
        method: 'PATCH',
        body: body?.data,
      }),
    }),
  }),
})

export const {
  useGetListPostQuery,
  useGetListPostByIdQuery,
  useUpPostMutation,
  useUpPostImageMutation,
  useGetFreeDaysQuery,
  useBookPostMutation,
  useShipperMoneyMutation,
  useGetListPostByAccountQuery,
  useAcceptBookerMutation,
  useGetPostHistoryQuery,
  useCancelBookMutation,
  useLikePostMutation,
  useDeleteLikePostMutation,
  useSavePostMutation,
  useDeleteSavePostMutation,
  useDeletePostMutation,
  useReopenPostMutation,
  useUpdatePostMutation,
} = postApi
