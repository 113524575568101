import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

export const pokemonApi = createApi({
  reducerPath: 'pokemonApi',
  baseQuery: fetchBaseQuery({ baseUrl: 'https://pokeapi.co/api/v2/' }),
  endpoints: (builder) => ({
    getPokemonByName: builder.query({
      query: (name) => ({
        url: `pokemon/${name}`,
      }),
    }),
    getListNamePokemon: builder.query({
      query: () => ({
        url: `pokemon?limit=151`,
      }),
    }),
  }),
})

export const { useGetPokemonByNameQuery, useGetListNamePokemonQuery } = pokemonApi
