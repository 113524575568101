import React, { useCallback, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import LogoProfile from '../../assets/image/face.svg'
import linkHomePage from '../../assets/image/link-Home-Page.svg'
import LogoLogout from '../../assets/image/logout.svg'
import LogoHistory from '../../assets/image/manage_history.svg'
import LogoNotification from '../../assets/image/notifications.svg'
import CustomButton from '../../components/UI/CustomButton/CustomButton'
import ModalLogin from '../../components/UI/Popup/ModalLogin'
import { useAuth } from '../../hooks/useAuth'
import { logout } from '../../store/reducer/authSlice'
import { useGetListNotificationQuery } from '../../services/Notification/notificationAPI.js'
import { numberedDisplayCount } from '../../utils/number'
import { Popover, Typography } from '@mui/material'
import NotificationItem from '../../components/common/NotificationItem/NotificationItem'

const HeaderLandingPage = ({}) => {
  const [open, setOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const authSLice = useAuth()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { isLogged } = authSLice
  const idMe = useSelector((state) => state.account.id)

  const { data: listNoti } = useGetListNotificationQuery(undefined, {
    refetchOnMountOrArgChange: true,
    pollingInterval: 5000,
  })

  const numberBadgeIcon = useMemo(() => {
    const lengthNotification = listNoti?.result?.filter(
      (item) => item?.toAccountStatus === 'Chưa đọc'
    ).length
    const numberBadge = numberedDisplayCount(lengthNotification)
    return numberBadge
  }, [listNoti?.result])

  const handleUpPost = useCallback(() => {
    if (!isLogged) {
      setOpen(true)
    } else {
      navigate('up-post')
    }
  }, [navigate, isLogged])

  const handleChangeRoute = () => {
    if (idMe) {
      navigate(`profile/${idMe}`)
    }
  }

  const handleLogout = useCallback(() => {
    dispatch(logout())
    navigate('home')
  }, [dispatch, navigate])

  const handleClickNotification = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleCloseNotification = () => {
    setAnchorEl(null)
  }

  const openNotification = Boolean(anchorEl)
  const idNoti = open ? 'simple-popover' : undefined

  return (
    <>
      <header className="p-3 m-auto flex justify-between items-center">
        <Link to="/">
          <div className="text-[40px] logo">'Exchange</div>
        </Link>
        <div className="link-page flex items-center ml-4">
          <Link to="/home">
            <img className="w-6	h-6 mr-5 cursor-pointer" src={linkHomePage} alt="" />
          </Link>
          <div className="button-link-page flex">
            {!isLogged && (
              <div className="mr-4">
                <CustomButton name="Đăng nhập" onClick={handleOpen} />
              </div>
            )}
            <div className="mr-4">
              <CustomButton name="Đăng bài" onClick={handleUpPost} />
            </div>

            {isLogged && (
              <div className="flex items-center">
                <Link to="/history">
                  <img className="w-6	h-6 mr-5 cursor-pointer" src={LogoHistory} alt="" />
                </Link>
                <div className="cursor-pointer relative">
                  <img
                    className="w-6	h-6 mr-5 cursor-pointer"
                    aria-describedby={idNoti}
                    onClick={handleClickNotification}
                    src={LogoNotification}
                    alt=""
                  />
                  {numberBadgeIcon > 0 && (
                    <sup
                      className={
                        'absolute right-1 -top-4 flex-center rounded-full text-[12px] font-semibold text-white select-none w-6 h-6 bg-red-500 leading-[23px] text-center'
                      }
                    >
                      {numberBadgeIcon}
                    </sup>
                  )}
                  <Popover
                    id={idNoti}
                    open={openNotification}
                    anchorEl={anchorEl}
                    onClose={handleCloseNotification}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                  >
                    {numberBadgeIcon > 0 ? (
                      listNoti?.result?.map((item, index) => (
                        <NotificationItem key={index} data={item} />
                      ))
                    ) : (
                      <Typography>The content of the Popover.</Typography>
                    )}
                  </Popover>
                </div>
                <img
                  className="w-6	h-6 mr-5 cursor-pointer"
                  src={LogoProfile}
                  alt=""
                  onClick={handleChangeRoute}
                />
                <img
                  className="w-6	h-6 mr-5 cursor-pointer"
                  src={LogoLogout}
                  alt=""
                  onClick={handleLogout}
                />
              </div>
            )}
          </div>
        </div>
      </header>
      <ModalLogin open={open} handleClose={handleClose} />
    </>
  )
}

export default HeaderLandingPage
