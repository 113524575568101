import { Box, FormControl, IconButton, Radio, RadioGroup, Typography } from '@mui/material'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import EditIcon from '@mui/icons-material/Edit'
import ModalChooseDay from './ModalChooseDay'
import { useMemo, useState } from 'react'
import { WEEK_BOOK } from '../../../constant/DateTime'
import { useGetListBranchSchoolQuery } from '../../../services/BranchSchool/branchSchoolAPI'

const styleInput = {
  background: 'rgb(246, 246, 246)',
  color: 'rgb(38, 38, 38)',
  marginBottom: '20px',
  p: '5px',
  borderRadius: '10px',
}

const FormBranchSchool = ({ getIdBranchData }) => {
  const [branchSchoolId, setBranchSchoolId] = useState(1)

  const { data: branchSchools } = useGetListBranchSchoolQuery(undefined, {
    refetchOnMountOrArgChange: true,
  })

  const handleChangeRadio = (event) => {
    setBranchSchoolId(event.target.value)
  }

  getIdBranchData(branchSchoolId)

  return (
    <FormGroup>
      <RadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        defaultValue={1}
        name="radio-buttons-group"
        onChange={handleChangeRadio}
      >
        {(branchSchools?.result || []).map((item, index) => {
          return (
            <>
              <FormControlLabel
                key={index}
                value={item?.id}
                control={<Radio />}
                label={item?.name}
              />
              <div className="ml-8">
                <Typography>{item?.address}</Typography>
              </div>
            </>
          )
        })}
      </RadioGroup>
    </FormGroup>
  )
}

const FormChooseDay = ({ getListChooseDay }) => {
  const [checklist, setChecklist] = useState(WEEK_BOOK)
  const [openModalChooseTime, setOpenModalChooseTime] = useState(false)
  const [dataDayFocus, setDataDayFocus] = useState({})

  const firstThreeElements = checklist.slice(0, 3)
  const lastThreeElements = checklist.slice(-3)

  const handleCloseModalLocation = () => {
    setOpenModalChooseTime(false)
  }

  const handleCheckboxChange = (event, index) => {
    const { checked } = event.target
    setChecklist(
      checklist.map((item) => {
        if (item.id === event.target.id && checked === true) {
          return {
            ...item,
            value: checked,
          }
        } else if (item.id === event.target.id && checked === false) {
          return {
            ...item,
            value: checked,
            time: item?.time?.map((item) => {
              return {
                ...item,
                value: false,
              }
            }),
          }
        }
        return item
      })
    )
  }

  const handleIconClick = (event) => {
    if (event.stopPropagation) {
      event.stopPropagation()
    }
    const id = event.currentTarget.id
    const findDay = checklist.find((item) => item.id === id)
    if (findDay.value) {
      setDataDayFocus(findDay)
      setOpenModalChooseTime(true)
    }
  }

  getListChooseDay(checklist)

  return (
    <>
      <FormControl className="w-full">
        <div className="flex justify-between">
          <div className="">
            {(firstThreeElements || [])?.map((day) => {
              return (
                <Box sx={styleInput}>
                  <FormControlLabel
                    className="pl-[11px]"
                    control={
                      <Checkbox id={day?.id} value={day?.value} onChange={handleCheckboxChange} />
                    }
                    label={day?.text}
                  />
                  <IconButton id={day?.id} onClick={handleIconClick} aria-label="edit">
                    <EditIcon color={day.value ? 'action' : 'disabled'} />
                  </IconButton>
                </Box>
              )
            })}
          </div>
          <div className="">
            {(lastThreeElements || [])?.map((day) => {
              return (
                <Box sx={styleInput}>
                  <FormControlLabel
                    className="pl-[11px]"
                    control={
                      <Checkbox id={day?.id} value={day?.value} onChange={handleCheckboxChange} />
                    }
                    label={day?.text}
                  />
                  <IconButton id={day?.id} onClick={handleIconClick} aria-label="edit">
                    <EditIcon color={day.value ? 'action' : 'disabled'} />
                  </IconButton>
                </Box>
              )
            })}
          </div>
        </div>
      </FormControl>
      <ModalChooseDay
        open={openModalChooseTime}
        handleClose={handleCloseModalLocation}
        data={dataDayFocus}
        handleGetDataListTime={(value) => {
          setChecklist(
            checklist?.map((item) => {
              if (item?.id === value?.id) {
                return {
                  ...value,
                }
              }
              return item
            })
          )
        }}
      />
    </>
  )
}

const ContentAtSchool = ({ handleGetBySchool, errorNotTime }) => {
  const [listDataChooseDay, setListDataChooseDay] = useState([])
  const [branchId, setBranchId] = useState(1)

  const getDataBySchool = useMemo(() => {
    return {
      listDataChooseDay,
      branchId,
    }
  }, [listDataChooseDay, branchId])

  handleGetBySchool(getDataBySchool)

  return (
    <div>
      <div className="flex justify-between">
        <div>
          <div className="mb-3">
            <Typography variant="h6">Chọn ngày và thời gian có thể giao đồ trên trường </Typography>
          </div>
          <FormChooseDay getListChooseDay={(data) => setListDataChooseDay(data)} />
        </div>
        <div>
          <div className="mb-3">
            <Typography variant="h6">Chọn cơ sở của trường đại học Sài Gòn</Typography>
          </div>
          <FormBranchSchool
            getIdBranchData={(branchId) => {
              setBranchId(branchId)
            }}
          />
        </div>
      </div>
      {!errorNotTime && (
        <div className="text-red-500">
          Chưa chọn thời gian! Hãy kiểu tra xem tất cả các ngày bạn chọn đã chọn ngày hay chưa!
          (Nhấn vào biểu tượng cây bút để điều chỉnh)
        </div>
      )}
    </div>
  )
}

export default ContentAtSchool
