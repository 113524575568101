import { FormControl, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material'
import { useMemo, useState } from 'react'
import { useGetAddressVNQuery } from '../../../services/Address/addressAPI'

const ContentAtLocation = ({ handleGet }) => {
  const [valueAddress, setValueAddress] = useState('')
  const [valuePhone, setValuePhone] = useState('')
  const [valueWeight, setValueWeight] = useState(0)
  const [city, setCity] = useState('')
  const [district, setDistrict] = useState('')

  const { data: locationVn } = useGetAddressVNQuery(undefined, { refetchOnMountOrArgChange: true })

  const districtNames = useMemo(() => {
    if (city) {
      return locationVn?.find((item) => item.name === city)
    }
  }, [city, locationVn])

  const resultDataForm = useMemo(() => {
    return {
      giverAddress: valueAddress,
      giverProvince: city,
      giverDistrict: district,
      giverPhone: valuePhone,
      weight: Number(valueWeight),
    }
  }, [valueAddress, valuePhone, city, district, valueWeight])

  handleGet(resultDataForm)

  return (
    <>
      <div>
        <div className="mb-5">
          <Typography variant="h6">Nhập thông tin nơi nhận hàng</Typography>
        </div>
        <div className="space-y-4">
          <TextField
            label="Địa chỉ chỗ nhận"
            required
            fullWidth
            size="small"
            onChange={(event) => {
              setValueAddress(event.target.value)
            }}
          />
          <div className="flex justify-between w-full">
            <div className="w-1/2 mr-4">
              <FormControl fullWidth size="small" required>
                <InputLabel id="demo-simple-select-label">Thành Phố/Tỉnh</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  onChange={(event) => {
                    setCity(event.target.value)
                  }}
                >
                  {locationVn?.map((item) => {
                    return <MenuItem value={item?.name}>{item?.name}</MenuItem>
                  })}
                </Select>
              </FormControl>
            </div>
            <div className="w-1/2">
              <FormControl fullWidth size="small" required>
                <InputLabel id="demo-simple-select-label">Quận/Huyện</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  onChange={(event) => {
                    setDistrict(event.target.value)
                  }}
                >
                  {(districtNames?.districts || [])?.map((item) => {
                    return <MenuItem value={item.name}>{item.name}</MenuItem>
                  })}
                </Select>
              </FormControl>
            </div>
          </div>
          <div className="flex justify-between w-full">
            <div className="w-1/2 mr-4">
              <TextField
                type="number"
                label="Cân nặng (KG)"
                required
                fullWidth
                size="small"
                onChange={(event) => {
                  setValueWeight(event.target.value)
                }}
              />
            </div>
            <div className="w-1/2">
              <TextField
                label="Số điện thoại"
                required
                fullWidth
                size="small"
                onChange={(event) => {
                  setValuePhone(event.target.value)
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ContentAtLocation
