import { Chip, Stack } from '@mui/material'
import dayjs from 'dayjs'
import AvatarDefault from '../../../assets/image/avatar-default.jpeg'
import { POST_TYPE } from '../../../constant'
import { useEffect, useMemo, useState } from 'react'
import CustomButton from '../../../components/UI/CustomButton/CustomButton'
import ModalConfirm from '../ModalConfirm/ModalConfirm'
import GradeIcon from '@mui/icons-material/Grade'
import ModalRating from '../../../components/UI/Popup/ModalRating/ModalRating'
import { useGetListRatingQuery, useRatingForGiverMutation } from '../../../services/User/userAPI'
import MyAlert from '../../../components/UI/Alert/Alert'
import { useSelector } from 'react-redux'

const ItemPostHistory = ({ data }) => {
  const [openConfirm, setOpenConfirm] = useState(false)
  const [openRating, setOpenRating] = useState(false)
  const [messageAlert, setMessageAlert] = useState('')
  const [typeAlert, setTypeAlert] = useState('')
  const [openAlert, setOpenAlert] = useState(false)

  const [ratingForGiver, { isLoading: loadingRating, isError, error }] = useRatingForGiverMutation()

  const {
    id,
    account,
    imagesPost,
    title,
    category,
    descriptionCategory,
    totalBooked,
    status,
    postType,
    expirationDate,
    bookSchedules,
    giverAddress,
    giverDistrict,
    giverProvince,
  } = data

  const messageWhereGive = useMemo(() => {
    if (postType === POST_TYPE.SCHOOL) {
      return 'Nhận ở trường đại học sài gòn'
    } else if (postType === POST_TYPE.ADDRESS) {
      return 'Nhận tại địa chỉ người cho'
    }
  }, [postType])

  const statusBookerWithPost = useMemo(() => {
    if (bookSchedules[0]?.status === 'Đang chờ') {
      return 'warning'
    } else if (bookSchedules[0]?.status === 'Huỷ') {
      return 'error'
    } else if (bookSchedules[0]?.status === 'Chấp nhận cho') {
      return 'success'
    }
  }, [bookSchedules])

  const textAddressByType = useMemo(() => {
    if (bookSchedules[0]?.bookType === POST_TYPE.SHIPPER) {
      return `Gửi đến: ${bookSchedules[0]?.shipAddress}`
    } else if (bookSchedules[0]?.bookType === POST_TYPE.ADDRESS) {
      return `Nhận tại: ${giverAddress}, ${giverDistrict}, ${giverProvince}`
    }
  }, [bookSchedules, giverAddress, giverDistrict, giverProvince])

  const handleCancel = () => {
    if (status === 'Đang cho') {
      setOpenConfirm(true)
    }
  }

  const submitRatingGiver = async (valueRating) => {
    const res = await ratingForGiver({ id_post: id, id_account: account?.id, star: valueRating })

    if (res?.data?.meta?.message === 'Success') {
      setOpenAlert(true)
      setTypeAlert('success')
      setMessageAlert('Đánh giá người dùng thành công!')
      setOpenRating(false)
    }
  }

  useEffect(() => {
    if (isError) {
      setOpenAlert(true)
      setTypeAlert('error')
      setMessageAlert(error?.data?.meta?.message)
    }
  }, [isError, error])

  return (
    <>
      <div className="bg-white shadow-lg rounded-md">
        <div className="p-4 flex justify-between">
          <div className="flex">
            {/* iamge */}
            <div className="image w-[130px] mr-4">
              <div className="pt-[100%] relative">
                <div className="absolute inset-0">
                  <div className="relative w-full h-full bg-transparent bg-gray-200 hover:bg-gray-100 transition duration-175 ease-in-out delay-0">
                    <picture className="absolute inset-0">
                      <img
                        className="w-full h-full rounded-md opacity-100 object-cover transition-opacity duration-350 ease-in-out delay-0"
                        src={imagesPost[0]?.url || AvatarDefault}
                        alt=""
                      />
                    </picture>
                  </div>
                </div>
              </div>
            </div>
            {/* infoPost */}
            <div className="flex flex-col	justify-between">
              <div>
                <span className="text-4 font-semibold">{title}</span>
                <div className="text-[16px] flex items-center">
                  <span>{category?.name}</span>
                  {descriptionCategory && <span>・</span>}
                  <span>{descriptionCategory}</span>
                </div>
                <p className="text-[14px] font-medium">{textAddressByType}</p>
                {bookSchedules[0]?.bookType === POST_TYPE.SCHOOL && (
                  <span className="text-[14px] font-medium">
                    Tại trường lúc: {bookSchedules[0]?.freeTimeToBook?.freeDayToBook?.day},{' '}
                    {bookSchedules[0]?.freeTimeToBook?.time}
                  </span>
                )}
                <Stack className="mt-2" direction="row" spacing={1}>
                  <Chip label={`${totalBooked} người đặt`} color="default" size="small" />
                  <Chip label={status} color="default" size="small" />
                  <Chip
                    label={bookSchedules[0]?.status}
                    color={statusBookerWithPost}
                    size="small"
                  />
                </Stack>
              </div>
              <div>
                <span className="text-[#E55E55] mt-3 text-[12px] font-bold">
                  {messageWhereGive}
                </span>
              </div>
            </div>
          </div>
          <div className="flex flex-col justify-between items-end">
            {bookSchedules[0]?.status === 'Đang chờ' && (
              <CustomButton name="Cancel" size="small" color="error" onClick={handleCancel} />
            )}

            {bookSchedules[0]?.status === 'Chấp nhận cho' && (
              <CustomButton
                name="Đánh giá"
                color="warning"
                startIcon={<GradeIcon />}
                onClick={() => setOpenRating(true)}
              />
            )}

            <div>
              <span className="text-[#808080] mt-3 text-[12px] font-bold">{`Kết thúc: ${dayjs(
                expirationDate
              ).format('DD/MM/YYYY')}`}</span>
            </div>
          </div>
        </div>
      </div>
      {openConfirm && (
        <ModalConfirm open={openConfirm} handleClose={() => setOpenConfirm(false)} idPost={id} />
      )}
      {openRating && (
        <ModalRating
          open={openRating}
          handleClose={() => setOpenRating(false)}
          onSubmit={(value) => submitRatingGiver(value)}
          accountId={account?.id}
          bookScheduleId={id}
          isLoading={loadingRating}
        />
      )}

      <MyAlert
        open={openAlert}
        type={typeAlert}
        message={messageAlert}
        handleClose={() => setOpenAlert(false)}
      />
    </>
  )
}

export default ItemPostHistory
