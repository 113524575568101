import * as React from 'react'
import HeaderLandingPage from './HeaderLandingPage'
import SectionHome from './SectionHome'

const LandingPage = ({}) => {
  return (
    <>
      <div className="body-landing-page w-full h-full">
        <div className="container-app m-auto">
          <HeaderLandingPage />
          <SectionHome />
        </div>
      </div>
    </>
  )
}

export default LandingPage
