import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { ADDRESS_API, API } from '../../constant'

const ADDRESS_ROOT = 'https://provinces.open-api.vn'

export const addressApi = createApi({
  reducerPath: ADDRESS_API,
  baseQuery: fetchBaseQuery({ baseUrl: ADDRESS_ROOT }),
  endpoints: (builder) => ({
    getAddressVN: builder.query({
      query: () => ({
        url: API.ADDRESS,
      }),
    }),
  }),
})

export const { useGetAddressVNQuery } = addressApi
