import { Box, Modal, Rating } from '@mui/material'
import { useEffect, useState } from 'react'
import CustomButton from '../../CustomButton/CustomButton'
import { useGetListRatingQuery } from '../../../../services/User/userAPI'
import { useSelector } from 'react-redux'

const style = {
  transform: 'translate(-50%, -50%)',
  width: 400,
  boxShadow: 24,
  borderRadius: '30px',
}

const ModalRating = ({
  open,
  handleClose,
  onSubmit = {},
  isLoading,
  accountId,
  bookScheduleId,
}) => {
  console.log('🚀 ~ file: ModalRating.jsx:22 ~ bookScheduleId:', bookScheduleId)
  console.log('🚀 ~ file: ModalRating.jsx:22 ~ accountId:', accountId)
  const [valueRating, setValueRating] = useState(0)

  const idMe = useSelector((state) => state.account.id)

  const { data: dataRatingValue } = useGetListRatingQuery(
    { evaluatingAccountId: idMe, evaluatedAccountId: accountId, bookScheduleId: bookScheduleId },
    { refetchOnMountOrArgChange: true }
  )

  const { star } = dataRatingValue?.result || {}

  return (
    <>
      <Modal
        keepMounted
        open={open}
        onClose={handleClose}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={style} className="absolute top-2/4	left-1/2 bg-white pb-3">
          <div className="flex justify-center font-bold text-[20px] mb-1 px-5 pt-1">
            Đánh giá người dùng
          </div>
          <hr />
          <div className="px-5 pt-5">
            <p className="text-4 font-bold text-center mb-4 text-yellow-500">
              Đánh giá người dùng khi cho đồ hoặc nhận đồ
            </p>
            <div className="mb-6 flex justify-center">
              <Rating
                name="simple-controlled"
                value={star || 0}
                onChange={(event, newValue) => {
                  setValueRating(newValue)
                }}
              />
            </div>

            <div className="space-x-4 flex justify-center">
              <CustomButton name="Hủy" size="small" color="default" onClick={handleClose} />
              <CustomButton
                name="Đánh giá"
                size="small"
                isLoading={isLoading}
                onClick={() => onSubmit(valueRating)}
              />
            </div>
          </div>
        </Box>
      </Modal>
    </>
  )
}

export default ModalRating
