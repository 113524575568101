import { Typography } from '@mui/material'
import dayjs from 'dayjs'
import { useEffect, useMemo, useState } from 'react'
import MyAlert from '../../components/UI/Alert/Alert'
import CustomButton from '../../components/UI/CustomButton/CustomButton'
import { POST_TYPE } from '../../constant/Constants'
import { useUpPostImageMutation, useUpPostMutation } from '../../services/Post/postAPI'
import FormInFoPost from './FormPost/FormInFoPost'
import FormTypePost from './FormPost/FormTypePost'
import FormUploadImage from './FormPost/FormUploadImage'

const UpPost = () => {
  const [dataInfoPost, setDataInfoPost] = useState({})
  const [dataArrayImage, setDataArrayImage] = useState([])
  const [dataTypePost, setDataTypePost] = useState('')
  const [errorNotTime, setErrorNotTime] = useState(true)
  const [isType, setIsType] = useState('')
  const [messageAlert, setMessageAlert] = useState('')
  const [typeAlert, setTypeAlert] = useState('')
  const [openAlert, setOpenAlert] = useState(false)

  const [upPost, { isLoading: loadingUpPost, isError, error: errorUpPost }] = useUpPostMutation()
  const [upPostImage, { isLoading: isLoadingUpImage }] = useUpPostImageMutation()

  const configDataDayTime = useMemo(() => {
    let result = []
    const findDayTrueValue = (dataTypePost?.dataGetFromSchool?.listDataChooseDay || [])?.filter(
      (item) => item.value === true
    )
    const noteTime = findDayTrueValue.some((item) => {
      return item.time.every((itemTime) => itemTime.value === false)
    })
    if (noteTime) {
      setErrorNotTime(false)
      return
    } else {
      setErrorNotTime(true)
      result = findDayTrueValue?.map((item) => {
        return {
          day: item?.id,
          times: item?.time.filter((item) => item.value === true).map((item) => item.id),
        }
      })
      return result
    }
  }, [dataTypePost])

  const handleOnSubmit = async () => {
    let dataPost = {}
    if (errorNotTime) {
      if (isType === POST_TYPE.SCHOOL) {
        dataPost = {
          title: dataInfoPost?.title,
          description: dataInfoPost?.description,
          descriptionCategory: dataInfoPost?.desCategory,
          expirationDate: dayjs(dataInfoPost?.dateEnd, 'DD/MM/YYYY').format(
            'YYYY-MM-DD[T]HH:mm:ss[Z]'
          ),
          weight: 1,
          postType: dataTypePost?.valueRadio,
          categoryId: dataInfoPost?.category,
          branchSchoolId: Number(dataTypePost?.dataGetFromSchool?.branchId),
          bookingsTime: [...configDataDayTime],
        }
      } else if (isType === POST_TYPE.ADDRESS) {
        dataPost = {
          title: dataInfoPost?.title,
          description: dataInfoPost?.description,
          descriptionCategory: dataInfoPost?.desCategory,
          expirationDate: dayjs(dataInfoPost?.dateEnd, 'DD/MM/YYYY').format(
            'YYYY-MM-DD[T]HH:mm:ss[Z]'
          ),
          postType: dataTypePost?.valueRadio,
          categoryId: dataInfoPost?.category,
          ...dataTypePost?.dataGetFromAddress,
        }
      }

      const resPost = await upPost(dataPost)
      if (resPost?.data?.result?.id) {
        const resImage = await upPostImage({
          data: dataArrayImage,
          id_post: resPost?.data?.result?.id,
        })
        if (resImage.data?.meta?.message || resPost.data?.meta?.message === 'Success') {
          setOpenAlert(true)
          setTypeAlert('success')
          setMessageAlert('Đăng bài thành công')
        } else {
          setOpenAlert(true)
          setTypeAlert('error')
          setMessageAlert('Đăng bài thất bại')
        }
      }
    }
  }

  useEffect(() => {
    if (isError) {
      setOpenAlert(true)
      setTypeAlert('error')
      setMessageAlert(errorUpPost?.data?.meta?.message)
    }
  }, [isError, errorUpPost])

  return (
    <div className="py-5">
      <div className="p-5 bg-white rounded-lg">
        <Typography variant="h5" style={{ marginBottom: '20px' }}>
          Đăng Bài
        </Typography>
        <div className="p-8 rounded-lg border-solid border-[1px] border-gray-500/20">
          <form>
            <div className="space-y-4">
              <FormInFoPost
                handleGetDataInfoPost={(value) => {
                  setDataInfoPost(value)
                }}
              />

              <FormUploadImage
                handleGetDataImage={(value) => {
                  setDataArrayImage(value)
                }}
              />

              <FormTypePost
                handleGetDataTypeGet={(postType) => {
                  setDataTypePost(postType)
                }}
                errorNotTime={errorNotTime}
                radioType={(value) => {
                  setIsType(value)
                }}
              />
            </div>

            <div className="flex justify-end mt-8">
              <CustomButton
                type="submit"
                name="Đăng Bài"
                onClick={handleOnSubmit}
                isLoading={loadingUpPost || isLoadingUpImage}
              />
            </div>
          </form>
        </div>
      </div>
      <MyAlert
        open={openAlert}
        type={typeAlert}
        message={messageAlert}
        handleClose={() => setOpenAlert(false)}
      />
    </div>
  )
}

export default UpPost
