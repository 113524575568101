import SendIcon from '@mui/icons-material/Send'
import {
  Box,
  FormControl,
  FormControlLabel,
  InputBase,
  InputLabel,
  MenuItem,
  Modal,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from '@mui/material'
import CustomButton from '../../../components/UI/CustomButton/CustomButton'
import { useEffect, useMemo, useState } from 'react'
import { useGetAddressVNQuery } from '../../../services/Address/addressAPI'
import { useBookPostMutation, useShipperMoneyMutation } from '../../../services/Post/postAPI'
import MyAlert from '../../../components/UI/Alert/Alert'
import dayjs from 'dayjs'
import { POST_TYPE } from '../../../constant'

const style = {
  transform: 'translate(-50%, -50%)',
  width: 500,
  boxShadow: 24,
  borderRadius: '30px',
}

const AtLocation = ({ data }) => {
  const { giverAddress, giverDistrict, giverPhone, giverProvince } = data

  return (
    <div className="mt-4">
      <div className="mb-3">
        <Typography variant="h6">Nhận tại địa chỉ:</Typography>
      </div>
      <div className="mb-3">
        <div className="text-[14px] font-bold flex items-center mb-1">
          Bạn hãy đến địa chỉ này để nhận đồ:
        </div>
        <div className="text-[13px] text-[#808080] flex flex-col ">
          <div>
            <span className="text-black font-medium">Địa chỉ: </span>
            <span>{giverAddress}</span>
          </div>
          <div>
            <span className="text-black font-medium">Quận/Huyện: </span>
            <span>{giverDistrict}</span>
          </div>
          <div>
            <span className="text-black font-medium">Thành Phố/Tỉnh: </span>
            <span>{giverProvince}</span>
          </div>
        </div>
      </div>
      <div>
        <div className="text-[14px] font-bold flex items-center mb-1">Số điện thoại người cho:</div>
        <div className="text-[13px] text-[#808080] flex items-center">
          <span>{giverPhone}</span>
        </div>
      </div>
    </div>
  )
}

const BookShipper = ({ data, getDataShip }) => {
  const [province, setProvince] = useState('')
  const [district, setDistrict] = useState('')
  const [address, setAddress] = useState('')
  const [messageAlert, setMessageAlert] = useState('')
  const [typeAlert, setTypeAlert] = useState('')
  const [openAlert, setOpenAlert] = useState(false)
  const [dataShip, setDataShip] = useState({})

  const { giverAddress, giverDistrict, giverProvince } = data
  const valueKm = dataShip?.distance?.text || '... km'
  const valueMoney = dataShip?.fee?.text || '... VNĐ '

  const { data: locationVn } = useGetAddressVNQuery(undefined, { refetchOnMountOrArgChange: true })
  const [shipperMoney, { isLoading, isError, error }] = useShipperMoneyMutation()

  const districtNames = useMemo(() => {
    if (province) {
      return locationVn?.find((item) => item.name === province)
    }
  }, [province, locationVn])

  const handleSetMoneyShipper = async () => {
    const data = {
      pickAddress: giverAddress,
      pickProvince: giverProvince,
      pickDistrict: giverDistrict,
      address,
      province,
      district,
      weight: 1,
      transport: 'road',
      deliverOption: 'none',
    }

    const res = await shipperMoney(data)
    if (res?.data?.meta?.message === 'Success') {
      setOpenAlert(true)
      setTypeAlert('success')
      setMessageAlert('Ước lượng tiền ship thành công')
      setDataShip(res?.data?.result)
      getDataShip({
        shipDistance: res?.data?.result?.distance?.value || 0,
        shipFee: res?.data?.result?.fee?.value || 0,
        shipAddress: address,
        shipProvince: province,
        shipDistrict: district,
      })
    }
  }

  useEffect(() => {
    if (isError) {
      setOpenAlert(true)
      setTypeAlert('error')
      setMessageAlert(error?.data?.meta?.message)
    }
  }, [isError, error])

  return (
    <div className="mt-3">
      <div className="mb-1">
        <div className="text-[16px] font-bold flex items-center">Giao từ:</div>
        <div className="text-[13px] text-[#808080] flex flex-col ">
          <div>
            <span className="text-black font-medium">Địa chỉ: </span>
            <span>{giverAddress}</span>
          </div>
          <div>
            <span className="text-black font-medium">Quận/Huyện: </span>
            <span>{giverDistrict}</span>
          </div>
          <div>
            <span className="text-black font-medium">Thành Phố/Tỉnh: </span>
            <span>{giverProvince}</span>
          </div>
        </div>
      </div>
      <div className="text-[14px] font-bold flex items-center mb-2">Giao đến:</div>
      <div className="w-full mb-2">
        <TextField
          className="w-full"
          label="Địa chỉ giao đến"
          id="outlined-size-small"
          size="small"
          onChange={(event) => {
            setAddress(event.target.value)
          }}
        />
      </div>
      <div className="flex justify-between w-full mb-2">
        <div className="w-1/2 mr-4">
          <FormControl fullWidth size="small" required>
            <InputLabel id="demo-simple-select-label">Thành Phố/Tỉnh</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              onChange={(event) => {
                setProvince(event.target.value)
              }}
            >
              {locationVn?.map((item) => {
                return <MenuItem value={item?.name}>{item?.name}</MenuItem>
              })}
            </Select>
          </FormControl>
        </div>
        <div className="w-1/2">
          <FormControl fullWidth size="small" required>
            <InputLabel id="demo-simple-select-label">Quận/Huyện</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              onChange={(event) => {
                setDistrict(event.target.value)
              }}
            >
              {(districtNames?.districts || [])?.map((item) => {
                return <MenuItem value={item.name}>{item.name}</MenuItem>
              })}
            </Select>
          </FormControl>
        </div>
      </div>
      <div className="mb-2">
        <CustomButton
          name="Ước lượng tiền ship"
          onClick={handleSetMoneyShipper}
          isLoading={isLoading}
        />
      </div>

      <div className="text-[14px] font-bold flex items-center mb-2">Khoảng cách: {valueKm}</div>
      <div className="text-[14px] font-bold flex items-center mb-2">
        Số tiến có thể phải thanh toán: {valueMoney}
      </div>
      <div className="text-[0.9rem] mt-1 text-[#E55E55]">
        <span className="block">Lưu ý:</span>
        <span className="block">- Số tiền ước lượng trên được lấy từ Giao Hàng Tiết Kiệm</span>
        <span>
          - Nếu bạn được xác nhận 'được cho' thì bạn hãy liên hệ với người cho để Book Shipper
          nhé!!!
        </span>
      </div>
      <MyAlert
        open={openAlert}
        type={typeAlert}
        message={messageAlert}
        handleClose={() => setOpenAlert(false)}
      />
    </div>
  )
}

const optionRadio = [
  {
    id: 'at-location',
    label: 'Nhận ở địa chỉ người cho',
  },
  {
    id: 'shipper',
    label: 'Đặt shipper',
  },
]

const ModalByLocation = ({ open = true, handleClose, data }) => {
  const [idRadio, setIdRadio] = useState(optionRadio[0]?.id)
  const [valueShip, setValueShip] = useState({
    shipDistance: 0,
    shipFee: 0,
    shipAddress: '',
    shipProvince: '',
    shipDistrict: '',
  })
  const [messageAlert, setMessageAlert] = useState('')
  const [typeAlert, setTypeAlert] = useState('')
  const [openAlert, setOpenAlert] = useState(false)

  const [bookPost, { isLoading, isError, error: errorBook }] = useBookPostMutation()

  const { id: id_post } = data
  const now = dayjs()

  const handleChangeRadio = (event) => {
    setIdRadio(event.target.value)
  }

  const handleBook = async () => {
    let data = {}

    if (idRadio === 'at-location') {
      data = {
        bookAt: now.format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
        note: 'note something',
        bookType: POST_TYPE.ADDRESS,
      }
    } else if (idRadio === 'shipper') {
      data = {
        bookAt: now.format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
        note: 'note something',
        bookType: POST_TYPE.SHIPPER,
        ...valueShip,
      }
    }

    const res = await bookPost({ data, id_post })
    if (res?.data?.meta?.message === 'Success') {
      setOpenAlert(true)
      setTypeAlert('success')
      setMessageAlert('Đặt thành công')
      handleClose()
    }
  }

  useEffect(() => {
    if (isError) {
      setOpenAlert(true)
      setTypeAlert('error')
      setMessageAlert(errorBook?.data?.meta?.message)
    }
  }, [isError, errorBook])

  return (
    <>
      <Modal
        keepMounted
        open={open}
        onClose={handleClose}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={style} className="absolute top-2/4	left-1/2 bg-white pb-3">
          <div className="flex justify-center font-bold text-[20px] mb-1 px-5 pt-1">
            Đặt theo địa chỉ
          </div>
          <hr />
          <div className="px-5 pt-5">
            <div className="mb-2">
              <Typography variant="h6">Chọn cách nhận đồ:</Typography>
            </div>
            <FormControl className="w-full" size="small">
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue={idRadio}
                name="radio-buttons-group"
                onChange={handleChangeRadio}
              >
                {(optionRadio || []).map((radio) => {
                  return (
                    <div>
                      <FormControlLabel
                        key={radio.id}
                        value={radio.id}
                        control={<Radio />}
                        label={radio.label}
                      />
                    </div>
                  )
                })}
              </RadioGroup>
              {idRadio === 'at-location' ? (
                <AtLocation data={data} />
              ) : (
                <BookShipper data={data} getDataShip={(value) => setValueShip(value)} />
              )}
              <div className="flex justify-center mt-5">
                <CustomButton
                  name="Đặt đồ"
                  size="small"
                  endIcon={<SendIcon />}
                  onClick={handleBook}
                  isLoading={isLoading}
                />
              </div>
            </FormControl>
          </div>
        </Box>
      </Modal>
      <MyAlert
        open={openAlert}
        type={typeAlert}
        message={messageAlert}
        handleClose={() => setOpenAlert(false)}
      />
    </>
  )
}

export default ModalByLocation
