import * as React from 'react'
import {
  useGetPokemonByNameQuery,
  useGetListNamePokemonQuery,
} from '../../services/Test/pokemonAPI'
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material'

const Test = () => {
  const [name, setName] = React.useState('')

  const hanleChangeSelect = React.useCallback((event) => {
    setName(event.target.value)
  }, [])

  const {
    data: dataName,
    error: errorName,
    isLoading: isLoadingName,
  } = useGetPokemonByNameQuery(name)
  const {
    data: dataList,
    error: errorList,
    isLoading: isLoadingList,
  } = useGetListNamePokemonQuery()

  return (
    <>
      <div className="flex justify-center">
        <div className="w-96 flex pt-8 justify-between">
          <Box sx={{ minWidth: 200 }}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Name Pokemon</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={name}
                label="Name Pokemon"
                onChange={hanleChangeSelect}
              >
                {dataList?.results.map((item, index) => {
                  return (
                    <MenuItem key={index} value={item.name}>
                      {item.name}
                    </MenuItem>
                  )
                })}
              </Select>
            </FormControl>
          </Box>
          <div className="">
            <h1>Image Pokemon</h1>
            <div className="m-auto">
              <img src={dataName?.sprites?.front_default} alt="" />
            </div>
          </div>
        </div>
      </div>
      <div className="mt-5">
        <Button variant="contained">Contained</Button>
      </div>
      <div className="mt-5">
        <Typography variant="h1">Hello</Typography>
      </div>
    </>
  )
}

export default Test
