import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { API, API_ROOT, REPORT_API } from '../../constant'

export const reportApi = createApi({
  reducerPath: REPORT_API,
  baseQuery: fetchBaseQuery({
    baseUrl: API_ROOT,
    prepareHeaders: (header, { getState }) => {
      const token = getState().auth.token
      if (token) {
        header.set('Authorization', `Bearer ${token}`)
      }
    },
  }),
  endpoints: (builder) => ({
    getListReport: builder.query({
      query: () => ({
        url: `${API.REPORT.LIST}`,
      }),
    }),
    reportPost: builder.mutation({
      query: (body) => ({
        url: `${API.REPORT.UP_REPORT}/${body?.id_post}${API.REPORT.LIST}/${body?.id_report}`,
        method: 'POST',
        body: {
          description: 'Report nè',
        },
      }),
    }),
  }),
})

export const { useGetListReportQuery, useReportPostMutation } = reportApi
