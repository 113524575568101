import {
  Box,
  FormControl,
  FormControlLabel,
  Modal,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material'
import { useEffect, useState } from 'react'
import MyAlert from '../../../components/UI/Alert/Alert'
import CustomButton from '../../../components/UI/CustomButton/CustomButton'
import { useGetListReportQuery, useReportPostMutation } from '../../../services/Report/reportAPI'
import LoadingComponent from '../../../components/UI/LoadingComponent/LoadingComponent'

const style = {
  transform: 'translate(-50%, -50%)',
  width: 400,
  boxShadow: 24,
  borderRadius: '30px',
}

const ModalReport = ({ open, handleClose, data, idReported }) => {
  const [messageAlert, setMessageAlert] = useState('')
  const [typeAlert, setTypeAlert] = useState('')
  const [openAlert, setOpenAlert] = useState(false)
  const [idReport, setIdReport] = useState(null)

  const { data: dataReport } = useGetListReportQuery(undefined, {
    refetchOnMountOrArgChange: true,
  })
  const [reportPost, { isLoading, isError, error }] = useReportPostMutation()

  const handleChangeRadio = (event) => {
    setIdReport(Number(event?.target?.value))
  }

  const handleReportPost = async () => {
    const res = await reportPost({ id_post: data?.id, id_report: idReport })

    if (res?.data?.meta?.message === 'Success') {
      setOpenAlert(true)
      setTypeAlert('success')
      setMessageAlert('Báo cáo bài đăng thành công!')
      handleClose()
    }
  }

  useEffect(() => {
    if (isError) {
      setOpenAlert(true)
      setTypeAlert('error')
      setMessageAlert(error?.data?.meta?.message)
    }
  }, [isError, error])

  return (
    <>
      <Modal
        keepMounted
        open={open}
        onClose={handleClose}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={style} className="absolute top-2/4	left-1/2 bg-white pb-3">
          <div className="flex justify-center font-bold text-[20px] mb-1 px-5 pt-1">
            Báo cáo bài đăng
          </div>
          <hr />
          <div className="px-5 pt-5">
            <div className="mb-2">
              {!idReported && <Typography variant="h6">Chọn lý do báo cáo bài đăng:</Typography>}
            </div>
            {!idReported ? (
              <FormControl className="w-full" size="small">
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  name="radio-buttons-group"
                  onChange={handleChangeRadio}
                >
                  {(dataReport?.result || []).map((radio) => {
                    return (
                      <div>
                        <FormControlLabel
                          key={radio.id}
                          value={radio.id}
                          control={<Radio />}
                          label={radio.name}
                        />
                      </div>
                    )
                  })}
                </RadioGroup>
                <div className="flex justify-center mt-5">
                  <div className="mr-4">
                    <CustomButton name="Hủy" size="small" color="default" onClick={handleClose} />
                  </div>
                  <div>
                    <CustomButton
                      name="Báo cáo"
                      size="small"
                      isLoading={isLoading}
                      onClick={handleReportPost}
                    />
                  </div>
                </div>
              </FormControl>
            ) : (
              <div className="text-center">
                <Typography variant="h6" color="error">
                  Bạn đã báo cáo bài đăng này!
                </Typography>
              </div>
            )}
          </div>
        </Box>
      </Modal>
      <MyAlert
        open={openAlert}
        type={typeAlert}
        message={messageAlert}
        handleClose={() => setOpenAlert(false)}
      />
    </>
  )
}

export default ModalReport
