import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { API, API_ROOT, CATEGORY_API } from '../../constant'

export const categoryApi = createApi({
  reducerPath: CATEGORY_API,
  baseQuery: fetchBaseQuery({ baseUrl: API_ROOT }),
  endpoints: (builder) => ({
    getListCategory: builder.query({
      query: () => ({
        url: API.CATEGORY.LIST,
      }),
    }),
  }),
})

export const { useGetListCategoryQuery } = categoryApi
