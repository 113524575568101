import { useMemo, useState } from 'react'
import { useGetListCategoryQuery } from '../../../services/Category/categoryAPI'
import { FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'

const FormInFoPost = ({ handleGetDataInfoPost }) => {
  const [title, setTitle] = useState('')
  const [dateEnd, setDateEnd] = useState('')
  const [category, setCategory] = useState('')
  const [desCategory, setDesCategory] = useState('')
  const [description, setDescription] = useState('')

  const { data: categories } = useGetListCategoryQuery(undefined, {
    refetchOnMountOrArgChange: true,
  })

  const dataOfInfoPost = useMemo(() => {
    const data = {
      title,
      dateEnd,
      category,
      desCategory,
      description,
    }

    return data
  }, [title, dateEnd, category, desCategory, description])

  handleGetDataInfoPost(dataOfInfoPost)

  return (
    <>
      <div className="flex justify-between w-full">
        <div className="w-1/2 mr-10">
          <TextField
            label="Tiêu đề"
            onChange={(event) => setTitle(event.target.value)}
            required
            fullWidth
            size="small"
          />
        </div>
        <div className="w-1/2">
          <DatePicker
            onChange={(event) => {
              setDateEnd(event.format('DD/MM/YYYY'))
            }}
            slotProps={{
              textField: {
                size: 'small',
                fullWidth: true,
                label: 'Ngày Kết Thúc',
                required: true,
              },
            }}
          />
        </div>
      </div>

      <div className="flex justify-between w-full">
        <div className="w-1/2 mr-10">
          <FormControl fullWidth size="small" required>
            <InputLabel id="demo-simple-select-label">Danh mục</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              onChange={(event) => {
                setCategory(event.target.value)
              }}
            >
              {(categories?.result || [])?.map((category, index) => {
                return (
                  <MenuItem key={index} value={category.id}>
                    {category.name}
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>
        </div>
        <div className="w-1/2">
          <TextField
            label="Mô tả danh mục"
            onChange={(event) => setDesCategory(event.target.value)}
            fullWidth
            size="small"
          />
        </div>
      </div>

      <TextField
        label="Mô tả chi tiết"
        multiline
        rows={5}
        variant="outlined"
        onChange={(event) => setDescription(event.target.value)}
        fullWidth
      />
    </>
  )
}

export default FormInFoPost
