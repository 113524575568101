import { Chip, Stack, Typography } from '@mui/material'
import dayjs from 'dayjs'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import CustomButton from '../../components/UI/CustomButton/CustomButton'
import LoadingComponent from '../../components/UI/LoadingComponent/LoadingComponent'
import ModalLogin from '../../components/UI/Popup/ModalLogin'
import { POST_TYPE } from '../../constant'
import { useAuth } from '../../hooks/useAuth'
import { useGetMeQuery } from '../../services/Account/accountAPI'
import {
  useDeleteLikePostMutation,
  useDeleteSavePostMutation,
  useGetListPostByIdQuery,
  useLikePostMutation,
  useSavePostMutation,
} from '../../services/Post/postAPI'
import HeaderCardPost from './HeaderCardPost/HeaderCardPost'
import ModalAtSchool from './ModalAtSchool/ModalAtSchool'
import ModalByLocation from './ModalByLocation/ModalByLocation'
import SliderImage from './SliderImage/SliderImage'
import FavoriteIcon from '@mui/icons-material/Favorite'
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder'
import { pink, red, yellow } from '@mui/material/colors'
import BookmarkIcon from '@mui/icons-material/Bookmark'
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder'
import ReportIcon from '@mui/icons-material/Report'
import ModalReport from './ModalReport/ModalReport'

const ButtonContactPost = ({ data, idMe }) => {
  const [openModalSchool, setOpenModalSchool] = useState(false)
  const [openModalLocation, setOpenModalLocation] = useState(false)
  const [openModalLogin, setOpenModalLogin] = useState(false)
  const [openModalReport, setOpenModalReport] = useState(false)
  const [toggleLike, setToggleLike] = useState(false)
  const [toggleSave, setToggleSave] = useState(false)

  const authSlice = useAuth()
  const [likePost, { isLoading: loadingLike }] = useLikePostMutation()
  const [deleteLikePost, { isLoading: loadingUnLike }] = useDeleteLikePostMutation()
  const [savePost, { isLoading: loadingSave }] = useSavePostMutation()
  const [deleteSavePost, { isLoading: loadingUnSave }] = useDeleteSavePostMutation()

  const { isLogged } = authSlice || {}

  const isLike = data?.likePosts?.map((like) => like?.account?.id).includes(idMe)
  const isSave = data?.savePosts?.map((save) => save?.account?.id).includes(idMe)
  const isBooked = data?.bookSchedules?.map((booked) => booked?.account?.id).includes(idMe)
  const idReported = data?.reportsPost?.map((reported) => reported?.account?.id).includes(idMe)

  const handleCloseModalSchool = () => {
    setOpenModalSchool(false)
  }

  const handleCloseModalLocation = () => {
    setOpenModalLocation(false)
  }

  const handleOpenPopupBook = useCallback(() => {
    if (isLogged) {
      if (data?.postType === POST_TYPE.SCHOOL) {
        setOpenModalSchool(true)
      } else if (data?.postType === POST_TYPE.ADDRESS) {
        setOpenModalLocation(true)
      }
    } else {
      setOpenModalLogin(true)
    }
  }, [data, isLogged])

  const disabledButton = useMemo(() => {
    if (idMe === data?.account?.id) return true
    return false
  }, [idMe, data])

  const handleToggleLike = async () => {
    if (isLogged) {
      if (toggleLike) {
        const res = await deleteLikePost(data?.id)
        if (res?.data?.meta?.message === 'Success') {
          setToggleLike(false)
        }
      } else {
        const res = await likePost(data?.id)
        if (res?.data?.meta?.message === 'Success') {
          setToggleLike(true)
        }
      }
    } else {
      setOpenModalLogin(true)
    }
  }

  const handleToggleSave = async () => {
    if (isLogged) {
      if (toggleSave) {
        const res = await deleteSavePost(data?.id)
        if (res?.data?.meta?.message === 'Success') {
          setToggleSave(false)
        }
      } else {
        const res = await savePost(data?.id)
        if (res?.data?.meta?.message === 'Success') {
          setToggleSave(true)
        }
      }
    } else {
      setOpenModalLogin(true)
    }
  }

  useEffect(() => {
    if (isLogged) {
      if (isLike) setToggleLike(isLike)
      if (isSave) setToggleSave(isSave)
    }
  }, [isLike, isLogged, isSave])

  return (
    <>
      <div className="button-post flex justify-between items-center">
        <div className="flex">
          <div className="cursor-pointer mr-2" onClick={handleToggleLike}>
            {(loadingLike || loadingUnLike) && <LoadingComponent size={20} />}
            {!(loadingLike || loadingUnLike) &&
              (toggleLike ? (
                <FavoriteIcon sx={{ color: pink[400] }} />
              ) : (
                <FavoriteBorderIcon sx={{ color: pink[400] }} />
              ))}
          </div>
          <div className="cursor-pointer mr-2" onClick={handleToggleSave}>
            {(loadingSave || loadingUnSave) && <LoadingComponent size={20} />}
            {!(loadingSave || loadingUnSave) &&
              (toggleSave ? (
                <BookmarkIcon sx={{ color: yellow[700] }} />
              ) : (
                <BookmarkBorderIcon sx={{ color: yellow[700] }} />
              ))}
          </div>
          <div
            className="cursor-pointer"
            onClick={() => {
              setOpenModalReport(true)
            }}
          >
            <ReportIcon sx={{ color: red[700] }} />
          </div>
        </div>
        <div className="text-end">
          <CustomButton
            name={isBooked ? 'Đã đặt' : 'Đặt'}
            size="small"
            onClick={handleOpenPopupBook}
            disabled={disabledButton || isBooked}
          />
          {disabledButton && (
            <Typography variant="h6" color="error">
              Bạn là người đăng bài này!
            </Typography>
          )}
        </div>
      </div>
      <ModalAtSchool open={openModalSchool} handleClose={handleCloseModalSchool} data={data} />
      <ModalByLocation
        open={openModalLocation}
        handleClose={handleCloseModalLocation}
        data={data}
      />
      <ModalLogin open={openModalLogin} handleClose={() => setOpenModalLogin(false)} />
      <ModalReport
        idReported={idReported}
        open={openModalReport}
        handleClose={() => setOpenModalReport(false)}
        data={data}
      />
    </>
  )
}

const InfoPost = ({ data }) => {
  const { title, description, descriptionCategory, category } = data || {}

  return (
    <div className="mt-2">
      <Typography variant="h6">{title}</Typography>
      <div className="text-[14px] flex items-center">
        <span>{category?.name}</span>
        {descriptionCategory && <span>・</span>}
        {descriptionCategory && <span>{descriptionCategory}</span>}
      </div>
      <div className="text-[0.9rem] mt-1 text-[#808080]">
        <span>{description}</span>
      </div>
    </div>
  )
}

const PostDetail = () => {
  const { id_post } = useParams()
  const { data, isLoading } = useGetListPostByIdQuery(id_post, { refetchOnMountOrArgChange: true })
  const { data: me } = useGetMeQuery(undefined, { refetchOnMountOrArgChange: true })

  const { id: idMe } = me?.result || {}

  const messageWhereGive = useMemo(() => {
    if (data?.result?.postType === POST_TYPE.SCHOOL) {
      return 'Nhận ở trường đại học sài gòn'
    } else if (data?.result?.postType === POST_TYPE.ADDRESS) {
      return 'Nhận tại địa chỉ người cho'
    }
  }, [data?.result?.postType])

  if (isLoading) {
    return <LoadingComponent />
  }

  return (
    <div className="relative m-auto max-w-[540px] pt-5 pb-5">
      <div className="rounded-[12px] bg-white pb-3">
        <HeaderCardPost account={data?.result?.account} />
        <SliderImage data={data} />
        <div className="px-5">
          <ButtonContactPost data={data?.result} idMe={idMe} />
          <div className="text-[14px]">
            <span>Thích bởi </span>
            <span className="font-bold"> {data?.result?.likePosts.length} người</span>
          </div>
          <Stack className="mt-2" direction="row" spacing={1}>
            <Chip label={`${data?.result?.totalBooked} người đặt`} color="success" size="small" />
            <Chip label={data?.result?.status} color="primary" size="small" />
          </Stack>
          <div className="pt-2">
            <InfoPost data={data?.result} />
          </div>
          <div className="mt-3 text-[12px] font-bold flex justify-between">
            <span className="text-[#E55E55]">{messageWhereGive}</span>
            <span className="text-[#808080]">{`Kết thúc: ${dayjs(
              data?.result?.expirationDate
            ).format('DD/MM/YYYY')}`}</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PostDetail
