import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { ACCOUNT_API, API, API_ROOT } from '../../constant'

export const accountApi = createApi({
  reducerPath: ACCOUNT_API,
  baseQuery: fetchBaseQuery({
    baseUrl: API_ROOT,
    prepareHeaders: (header, { getState }) => {
      const token = getState().auth.token
      if (token) {
        header.set('Authorization', `Bearer ${token}`)
      }
    },
  }),
  endpoints: (builder) => ({
    getMe: builder.query({
      query: () => ({
        url: API.ACCOUNT.ME,
      }),
    }),

    getAccount: builder.query({
      query: (account_id) => ({
        url: `${API.ACCOUNT.ACCOUNT}/${account_id}`,
      }),
    }),

    getBookers: builder.query({
      query: (id_post) => ({
        url: `${API.ACCOUNT.UPDATE}/post/${id_post}${API.ACCOUNT.LIST_BOOKER}`,
      }),
    }),

    updateInfoProfile: builder.mutation({
      query: (body) => ({
        url: API.ACCOUNT.UPDATE,
        method: 'PATCH',
        body,
      }),
    }),
  }),
})

export const {
  useGetMeQuery,
  useGetAccountQuery,
  useUpdateInfoProfileMutation,
  useGetBookersQuery,
} = accountApi
