import { Chip, Stack, Typography } from '@mui/material'
import { Link } from 'react-router-dom'
import imageDefault from '../../../assets/image/default-product-image.png'
import { useMemo } from 'react'

const PostItem = ({ post }) => {
  const { id, descriptionCategory, category, status, title, totalBooked, imagesPost } = post || {}

  const messageStatusVn = useMemo(() => {
    if (status === 'Đang cho') {
      return {
        message: 'Đang cho',
        color: 'warning',
      }
    } else if (status === 'Đã cho') {
      return {
        message: 'Đã cho',
        color: 'primary',
      }
    } else {
      return {
        message: 'Bị khóa',
        color: 'error',
      }
    }
  }, [status])

  return (
    <>
      <Link to={`/post/${id}`}>
        <div className="pt-[100%] relative">
          <div className="absolute inset-0">
            <div className="relative w-full h-full bg-transparent bg-gray-200 hover:bg-gray-100 transition duration-175 ease-in-out delay-0">
              <picture className="absolute inset-0">
                <img
                  className="w-full h-full rounded-[30px] opacity-100 object-cover transition-opacity duration-350 ease-in-out delay-0"
                  src={imagesPost[0]?.url || imageDefault}
                  alt=""
                />
              </picture>
            </div>
          </div>
        </div>
        <div className="ml-2">
          <div className="pt-2 pb-2">
            <Typography variant="h6">{title}</Typography>
            <div className="text-[14px] flex items-center">
              <span>{category?.name}</span>
              {descriptionCategory && <span>・</span>}
              <span>{descriptionCategory}</span>
            </div>
          </div>
          <Stack direction="row" spacing={1}>
            <Chip label={`${totalBooked} người đặt`} color="success" size="small" />
            <Chip label={messageStatusVn?.message} color={messageStatusVn?.color} size="small" />
          </Stack>
        </div>
      </Link>
    </>
  )
}

export default PostItem
