export const POST_TYPE = {
  SCHOOL: 'Tại trường',
  ADDRESS: 'Tại địa chỉ',
  SHIPPER: 'Shipper',
}

export const NOTIFICATION_TYPE = {
  HAS_BOOKER: 'Có người book',
  ACCEPTED_GIVE: 'Đồng ý cho',
  FOLLOW_POSTED: 'Người follow đăng bài',
  POST_BY_REPORT: 'Bài đăng bị khoá do report',
}
