import { Typography } from '@mui/material'
import ItemPostHistory from './ItemPostHistory/ItemPostHistory'
import { useGetPostHistoryQuery } from '../../services/Post/postAPI'
import LoadingComponent from '../../components/UI/LoadingComponent/LoadingComponent'

const History = () => {
  const { data, isLoading } = useGetPostHistoryQuery(undefined, { refetchOnMountOrArgChange: true })

  return (
    <>
      <div className="pt-16 pb-4">
        <Typography variant="h5" style={{ marginBottom: '32px' }}>
          Lịch sử đặt lịch
        </Typography>
      </div>
      {isLoading && <LoadingComponent />}
      <div className="space-y-4 pb-5">
        {data?.result?.map((item) => {
          return <ItemPostHistory data={item} />
        })}
      </div>
    </>
  )
}

export default History
