import {
  Box,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Modal,
  Radio,
  RadioGroup,
  Select,
  Typography,
} from '@mui/material'
import { useEffect, useMemo, useState } from 'react'
import CustomButton from '../../../components/UI/CustomButton/CustomButton'
import SendIcon from '@mui/icons-material/Send'
import { useBookPostMutation, useGetFreeDaysQuery } from '../../../services/Post/postAPI'
import dayjs from 'dayjs'
import MyAlert from '../../../components/UI/Alert/Alert'

const style = {
  transform: 'translate(-50%, -50%)',
  width: 400,
  boxShadow: 24,
  borderRadius: '30px',
}

const ModalAtSchool = ({ open = true, handleClose, data }) => {
  const [day, setDay] = useState('')
  const [idTime, setIdTime] = useState(0)
  const [messageAlert, setMessageAlert] = useState('')
  const [typeAlert, setTypeAlert] = useState('')
  const [openAlert, setOpenAlert] = useState(false)

  const { id: id_post, postType } = data
  const now = dayjs()

  const { data: freeDays } = useGetFreeDaysQuery(id_post, { refetchOnMountOrArgChange: true })
  const [bookPost, { isLoading, isError, error: errorBook }] = useBookPostMutation()

  const handleChange = (event) => {
    setDay(event.target.value)
  }

  const handleChangeTime = (event) => {
    setIdTime(event.target.value)
  }

  const listTime = useMemo(() => {
    const result = freeDays?.result?.find((item) => item?.day === day)
    return result
  }, [freeDays, day])

  const handleBookSchedule = async () => {
    const data = {
      freeTimeToBookId: Number(idTime),
      bookType: postType,
      note: 'note something',
      bookAt: now.format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
    }

    const res = await bookPost({ data, id_post })

    if (res?.data?.meta?.message === 'Success') {
      setOpenAlert(true)
      setTypeAlert('success')
      setMessageAlert('Đặt thành công')
      handleClose()
    }
  }

  useEffect(() => {
    if (isError) {
      setOpenAlert(true)
      setTypeAlert('error')
      setMessageAlert(errorBook?.data?.meta?.message)
    }
  }, [isError, errorBook])

  return (
    <>
      <Modal
        keepMounted
        open={open}
        onClose={handleClose}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={style} className="absolute top-2/4	left-1/2 bg-white pb-3">
          <div className="flex justify-center font-bold text-[20px] mb-1 px-5 pt-1">
            Đặt lịch nhận đồ tại trường
          </div>
          <hr />
          <div className="px-5 pt-5">
            <div className="mb-2">
              <Typography variant="h6">Chọn ngày nhận đồ:</Typography>
            </div>
            <FormControl className="w-full" size="small">
              <InputLabel id="demo-select-small-label">Hãy chọn ngày</InputLabel>
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                value={day}
                label="Chọn ngày nhận đồ"
                onChange={handleChange}
              >
                {(freeDays?.result || [])?.map((item) => {
                  return <MenuItem value={item?.day}>{item?.day}</MenuItem>
                })}
              </Select>
              <div className="mt-4">
                <Typography variant="h6">Chọn giờ nhận đồ:</Typography>
              </div>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="female"
                name="radio-buttons-group"
                onChange={handleChangeTime}
              >
                {listTime?.freeTimesToBook?.map((item) => {
                  return (
                    <div>
                      <FormControlLabel value={item?.id} control={<Radio />} label={item?.time} />
                    </div>
                  )
                })}
              </RadioGroup>
              <div className="flex justify-center mt-5">
                <CustomButton
                  name="Đặt lịch"
                  size="small"
                  isLoading={isLoading}
                  endIcon={<SendIcon />}
                  onClick={handleBookSchedule}
                />
              </div>
            </FormControl>
          </div>
        </Box>
      </Modal>
      <MyAlert
        open={openAlert}
        type={typeAlert}
        message={messageAlert}
        handleClose={() => setOpenAlert(false)}
      />
    </>
  )
}

export default ModalAtSchool
