import { ThemeProvider } from '@mui/material'
import theme from './theme'
import RoutesPage from './routes/routes'
import './assets/scss/style.scss'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

function App() {
  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <div className="App">
          <RoutesPage></RoutesPage>
        </div>
      </LocalizationProvider>
    </ThemeProvider>
  )
}

export default App
