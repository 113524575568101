import { Box, Modal } from '@mui/material'
import { useEffect, useState } from 'react'
import MyAlert from '../../../components/UI/Alert/Alert'
import CustomButton from '../../../components/UI/CustomButton/CustomButton'
import {
  useCancelBookMutation,
  useDeletePostMutation,
  useGetListPostByAccountQuery,
  useGetPostHistoryQuery,
} from '../../../services/Post/postAPI'
import { useParams } from 'react-router-dom'

const style = {
  transform: 'translate(-50%, -50%)',
  width: 400,
  boxShadow: 24,
  borderRadius: '30px',
}

const ModalConfirm = ({ open, handleClose, idPost }) => {
  const { id_profile } = useParams()
  const [messageAlert, setMessageAlert] = useState('')
  const [typeAlert, setTypeAlert] = useState('')
  const [openAlert, setOpenAlert] = useState(false)

  const [deletePost, { isLoading, isError, error }] = useDeletePostMutation()
  const { data, refetch } = useGetListPostByAccountQuery(
    { id_account: id_profile },
    { refetchOnMountOrArgChange: true }
  )

  const handleCancel = async () => {
    const res = await deletePost(idPost)

    if (res?.data?.meta?.message === 'Success') {
      await refetch()
      setOpenAlert(true)
      setTypeAlert('success')
      setMessageAlert('Xóa bài đăng thành công')
      handleClose()
    }
  }

  useEffect(() => {
    if (isError) {
      setOpenAlert(true)
      setTypeAlert('error')
      setMessageAlert(error?.data?.meta?.message)
    }
  }, [isError, error])

  return (
    <>
      <Modal
        keepMounted
        open={open}
        onClose={handleClose}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={style} className="absolute top-2/4	left-1/2 bg-white pb-3">
          <div className="flex justify-center font-bold text-[20px] mb-1 px-5 pt-1">
            Xác nhận xóa bài đăng
          </div>
          <hr />
          <div className="px-5 pt-5">
            <p className="text-4 font-bold text-center mb-4 text-red-500">
              Bạn có chắc là xóa bài đăng không?
            </p>
            <div className="space-x-4 flex justify-center">
              <CustomButton name="Không" size="small" color="default" onClick={handleClose} />
              <CustomButton
                name="Đồng ý"
                size="small"
                onClick={handleCancel}
                isLoading={isLoading}
              />
            </div>
          </div>
        </Box>
      </Modal>
      <MyAlert
        open={openAlert}
        type={typeAlert}
        message={messageAlert}
        handleClose={() => setOpenAlert(false)}
      />
    </>
  )
}

export default ModalConfirm
