import { Box, Checkbox, FormControlLabel, Modal } from '@mui/material'
import { useEffect, useState } from 'react'
import CustomButton from '../../../components/UI/CustomButton/CustomButton'

const styleInput = {
  background: 'rgb(246, 246, 246)',
  color: 'rgb(38, 38, 38)',
  marginBottom: '20px',
  p: '5px',
  borderRadius: '10px',
}
const style = {
  transform: 'translate(-50%, -50%)',
  width: 400,
  boxShadow: 24,
  borderRadius: '30px',
}

const ModalChooseDay = ({ open = true, handleClose, data, handleGetDataListTime }) => {
  const [listTime, setListTime] = useState(data.time)

  const handleChangeCheckbox = (id) => (event) => {
    const { checked } = event.target
    const updatedTimeList = listTime.map((time) =>
      time.id === id ? { ...time, value: checked } : time
    )
    setListTime(updatedTimeList)
  }

  const handleSaveTime = () => {
    handleGetDataListTime({ ...data, time: listTime })
    handleClose()
  }

  useEffect(() => {
    setListTime(data?.time)
  }, [data?.time])

  const timeCheckboxes = (listTime || [])?.map((time, index) => {
    return (
      <Box sx={styleInput}>
        <FormControlLabel
          className="pl-[11px]"
          control={
            <Checkbox key={index} checked={time?.value} onChange={handleChangeCheckbox(time?.id)} />
          }
          label={time?.text}
        />
      </Box>
    )
  })

  return (
    <>
      <Modal
        keepMounted
        open={open}
        onClose={handleClose}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={style} className="absolute top-2/4	left-1/2 bg-white pb-3">
          <div className="flex justify-center font-bold text-[20px] mb-1 px-5 pt-1">
            {`${data?.text} - Chọn thời gian rảnh`}
          </div>
          <hr />
          <div className="px-5 pt-5">{timeCheckboxes}</div>
          <div className="flex justify-center mt-5">
            <CustomButton name="Lưu" size="small" onClick={handleSaveTime} />
          </div>
        </Box>
      </Modal>
    </>
  )
}

export default ModalChooseDay
