import { DropzoneArea } from 'mui-file-dropzone'
import { useState } from 'react'

const FormUploadImage = ({ handleGetDataImage }) => {
  const [pathName, setPathName] = useState([])

  handleGetDataImage(pathName)

  const handleUpload = (files) => {
    const formData = new FormData()

    for (let i = 0; i < files.length; i++) {
      formData.append('files', files[i])
    }

    setPathName(formData)
  }

  return (
    <div className="flex w-full">
      <DropzoneArea
        dropzoneClass="w-[100px] h-[100px]"
        acceptedFiles={['image/*']}
        dropzoneText="Chọn tối đa 4 hình"
        onChange={handleUpload}
        filesLimit={4}
        maxFileSize={4000000}
      />
    </div>
  )
}

export default FormUploadImage
