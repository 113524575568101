import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'
import {
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
  persistReducer,
  persistStore,
} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import authSlice from '../store/reducer/authSlice'
import accountSlice from '../store/reducer/accountSlice'
import { accountApi } from './Account/accountAPI'
import { authApi } from './Auth/authAPI'
import { addressApi } from './Address/addressAPI'
import { branchSchoolApi } from './BranchSchool/branchSchoolAPI'
import { categoryApi } from './Category/categoryAPI'
import { postApi } from './Post/postAPI'
import { pokemonApi } from './Test/pokemonAPI'
import { notificationApi } from './Notification/notificationAPI'
import { reportApi } from './Report/reportAPI'
import { userApi } from './User/userAPI'

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth', 'account'],
}

const rootReducer = combineReducers({
  [pokemonApi.reducerPath]: pokemonApi.reducer,
  [authApi.reducerPath]: authApi.reducer,
  [postApi.reducerPath]: postApi.reducer,
  [branchSchoolApi.reducerPath]: branchSchoolApi.reducer,
  [categoryApi.reducerPath]: categoryApi.reducer,
  [accountApi.reducerPath]: accountApi.reducer,
  [addressApi.reducerPath]: addressApi.reducer,
  [notificationApi.reducerPath]: notificationApi.reducer,
  [reportApi.reducerPath]: reportApi.reducer,
  [userApi.reducerPath]: userApi.reducer,
  auth: authSlice,
  account: accountSlice,
})

const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = configureStore({
  reducer: persistedReducer,

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    })
      .concat(pokemonApi.middleware)
      .concat(authApi.middleware)
      .concat(postApi.middleware)
      .concat(branchSchoolApi.middleware)
      .concat(categoryApi.middleware)
      .concat(accountApi.middleware)
      .concat(addressApi.middleware)
      .concat(notificationApi.middleware)
      .concat(reportApi.middleware)
      .concat(userApi.middleware),
})

const persistor = persistStore(store)

setupListeners(store.dispatch)

export { persistor, store }
