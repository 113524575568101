import { createTheme } from '@mui/material/styles'

const theme = createTheme({
  palette: {
    primary: {
      main: '#5579C6',
    },
    secondary: {
      main: '#183A60',
    },
    default: {
      main: '#fffff',
    },
  },
  typography: {
    fontFamily: 'inherit',
    h1: {
      fontSize: 40,
      fontWeight: 600,
      color: 'rgb(38, 38, 38)',
    },
    h2: {
      fontSize: 60,
      fontWeight: 300,
    },
    h3: {
      fontSize: 48,
      fontWeight: 400,
    },
    h4: {
      fontSize: 34,
      fontWeight: 400,
    },
    h5: {
      fontSize: 26,
      fontWeight: 600,
      lineHeight: 1.5,
      letterSpacing: 1,
    },
    h6: {
      fontSize: 16,
      fontWeight: 600,
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    p: {
      fontSize: 14,
      fontWeight: 500,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          fontSize: 16,
          textTransform: 'none',
        },
      },
    },
  },
})

export default theme
