import { LoadingButton } from '@mui/lab'

const CustomButton = ({ isLoading, ...props }) => {
  return (
    <>
      <LoadingButton
        {...props}
        sx={{ borderRadius: '10px', fontSize: '16px' }}
        variant="contained"
        loading={isLoading}
      >
        {props.name}
      </LoadingButton>
    </>
  )
}

export default CustomButton
