import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { API, API_ROOT, AUTH_API } from '../../constant'

export const authApi = createApi({
  reducerPath: AUTH_API,
  baseQuery: fetchBaseQuery({ baseUrl: API_ROOT }),
  tagTypes: ['AUTH'],
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (body) => ({
        url: API.AUTH.SIGN_IN,
        method: 'POST',
        body,
      }),
    }),
    register: builder.mutation({
      query: (body) => ({
        url: API.AUTH.SIGN_UP,
        method: 'POST',
        body,
      }),
    }),
  }),
})

export const { useLoginMutation, useRegisterMutation } = authApi
