import Slider from 'react-slick'
import './slider.scss'
import imageDefault from '../../../assets/image/default-product-image.png'

const CustomSlickButton = ({ onClick, type = 'left' }) => {
  return (
    <button className={`button-custom button-custom-${type}`} onClick={onClick}>
      <div color="inherit">
        <svg xmlns="http://www.w3.org/2000/svg" width="8" height="14">
          <path
            fill="#262626"
            fill-rule="nonzero"
            d="M7.707.293A1 1 0 0 0 6.387.21l-.094.083-6 6a1 1 0 0 0-.083 1.32l.083.094 6 6a1 1 0 0 0 1.497-1.32l-.083-.094L2.415 7l5.292-5.293A1 1 0 0 0 7.79.387L7.707.293Z"
          ></path>
        </svg>
      </div>
    </button>
  )
}

const ImageSlider = ({ url = imageDefault }) => {
  return (
    <div className="w-full inline-block">
      <div className="pt-[100%] relative">
        <div className="absolute inset-0">
          <div className="relative w-full h-full bg-transparent	transition duration-175 ease-in-out bg-red-500 hover:bg-blue-500">
            <picture className="absolute inset-0">
              <img src={url} alt="" />
            </picture>
          </div>
        </div>
      </div>
    </div>
  )
}

const SliderImage = ({ data }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <CustomSlickButton type="left" />,
    nextArrow: <CustomSlickButton type="right" />,
  }

  const { imagesPost } = data?.result

  return (
    <div className="pb-7">
      <Slider {...settings}>
        {imagesPost.length > 0 ? (
          imagesPost?.map((item) => {
            return <ImageSlider url={item?.url} />
          })
        ) : (
          <ImageSlider />
        )}
      </Slider>
    </div>
  )
}

export default SliderImage
