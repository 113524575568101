import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import {
  Avatar,
  Box,
  Chip,
  CircularProgress,
  IconButton,
  Modal,
  Stack,
  Typography,
} from '@mui/material'
import cn from 'classnames'
import { useEffect, useMemo, useState } from 'react'
import AvatarDefault from '../../../assets/image/avatar-default.jpeg'
import MyAlert from '../../../components/UI/Alert/Alert'
import { POST_TYPE } from '../../../constant'
import { useGetBookersQuery } from '../../../services/Account/accountAPI'
import { useAcceptBookerMutation } from '../../../services/Post/postAPI'
import { Link } from 'react-router-dom'
import GradeIcon from '@mui/icons-material/Grade'
import CustomButton from '../../../components/UI/CustomButton/CustomButton'
import ModalRating from '../../../components/UI/Popup/ModalRating/ModalRating'
import { useGetListRatingQuery, useRatingForReceiverMutation } from '../../../services/User/userAPI'
import StarIcon from '@mui/icons-material/Star'
import { yellow } from '@mui/material/colors'
import numeral from 'numeral'
import { useSelector } from 'react-redux'

const style = {
  transform: 'translate(-50%, -50%)',
  width: 1000,
  boxShadow: 24,
  borderRadius: '30px',
}

const ItemPeopleBook = ({ data, isPostGiving, handleAcceptBook, isLoading }) => {
  const [openRating, setOpenRating] = useState(false)
  const [messageAlert, setMessageAlert] = useState('')
  const [typeAlert, setTypeAlert] = useState('')
  const [openAlert, setOpenAlert] = useState(false)

  const [ratingForReceiver, { isLoading: loadingRating, isError, error }] =
    useRatingForReceiverMutation()

  const { id, fullName, avatar, bookSchedules, post, reputation } = data

  const textAddressByType = useMemo(() => {
    if (bookSchedules[0]?.bookType === POST_TYPE.SHIPPER) {
      return `Gửi đến: ${bookSchedules[0]?.shipAddress}`
    } else if (bookSchedules[0]?.bookType === POST_TYPE.ADDRESS) {
      return `Nhận tại: ${post?.giverAddress}, ${post?.giverDistrict}, ${post?.giverProvince}`
    }
  }, [bookSchedules, post])

  const statusBookerWithPost = useMemo(() => {
    if (bookSchedules[0]?.status === 'Đang chờ') {
      return 'warning'
    } else if (bookSchedules[0]?.status === 'Huỷ') {
      return 'error'
    } else if (bookSchedules[0]?.status === 'Chấp nhận cho') {
      return 'success'
    }
  }, [bookSchedules])

  const submitBook = () => {
    handleAcceptBook({ id_post: post?.id, id_account: id })
  }

  const submitRatingReceiver = async (valueRating) => {
    const res = await ratingForReceiver({ id_post: post?.id, id_account: id, star: valueRating })

    if (res?.data?.meta?.message === 'Success') {
      setOpenAlert(true)
      setTypeAlert('success')
      setMessageAlert('Đánh giá người dùng thành công!')
      setOpenRating(false)
    }
  }

  useEffect(() => {
    if (isError) {
      setOpenAlert(true)
      setTypeAlert('error')
      setMessageAlert(error?.data?.meta?.message)
    }
  }, [isError, error])

  return (
    <>
      <div
        className={cn('bg-sky-200 shadow-lg rounded-xl', {
          'bg-sky-400': bookSchedules[0]?.status === 'Chấp nhận cho',
          'bg-sky-100': bookSchedules[0]?.status !== 'Chấp nhận cho' && isPostGiving,
        })}
      >
        <div className="p-3 flex justify-between">
          <div className="flex items-center">
            <div className="flex">
              <Link to={`/profile/${id}`}>
                <Avatar alt="avatar" src={avatar || AvatarDefault} className="mr-2" />
              </Link>
            </div>
            <div>
              <Link to={`/profile/${id}`}>
                <div>
                  <span className="text-[14px] font-semibold">{fullName} </span>
                  <span className="text-[14px]">
                    ({numeral(reputation).format('0.0')}
                    <StarIcon sx={{ color: yellow[500], fontSize: 18 }} />)
                  </span>
                </div>
              </Link>
              <p className="text-[14px] ">{textAddressByType}</p>
              {bookSchedules[0]?.bookType === POST_TYPE.SCHOOL && (
                <span className="text-[14px]">
                  Tại trường lúc: {bookSchedules[0]?.freeTimeToBook?.freeDayToBook?.day},{' '}
                  {bookSchedules[0]?.freeTimeToBook?.time}
                </span>
              )}
              <Stack className="mt-2" direction="row" spacing={1}>
                <Chip label={bookSchedules[0]?.status} color={statusBookerWithPost} size="small" />
                <Chip label={bookSchedules[0]?.bookType} color="primary" size="small" />
              </Stack>
            </div>
          </div>
          {!isPostGiving ? (
            <div className="flex items-center cursor-pointer" onClick={submitBook}>
              {isLoading ? <CircularProgress /> : <CheckCircleIcon />}
            </div>
          ) : (
            bookSchedules[0]?.status === 'Chấp nhận cho' && (
              <div className="flex items-center">
                <CustomButton
                  name="Đánh giá"
                  color="warning"
                  startIcon={<GradeIcon />}
                  onClick={() => setOpenRating(true)}
                />
              </div>
            )
          )}
        </div>
      </div>
      {openRating && (
        <ModalRating
          open={openRating}
          handleClose={() => setOpenRating(false)}
          onSubmit={(value) => submitRatingReceiver(value)}
          bookScheduleId={bookSchedules[0]?.id}
          accountId={id}
          isLoading={loadingRating}
        />
      )}
      <MyAlert
        open={openAlert}
        type={typeAlert}
        message={messageAlert}
        handleClose={() => setOpenAlert(false)}
      />
    </>
  )
}

const ModalPeopleBook = ({ open, handleClose, idPost }) => {
  const [messageAlert, setMessageAlert] = useState('')
  const [typeAlert, setTypeAlert] = useState('')
  const [openAlert, setOpenAlert] = useState(false)

  const { data } = useGetBookersQuery(idPost, { refetchOnMountOrArgChange: true })
  const [acceptBooker, { isLoading, isError, error }] = useAcceptBookerMutation()

  const isPostGiving = useMemo(() => {
    return data?.result?.some((item) => item?.bookSchedules[0]?.status === 'Chấp nhận cho')
  }, [data])

  const handleAcceptBook = async (value) => {
    const res = await acceptBooker(value)

    if (res?.data?.meta?.message === 'Success') {
      setOpenAlert(true)
      setTypeAlert('success')
      setMessageAlert('Chấp nhận thành công thành công')
      handleClose()
    }
  }

  useEffect(() => {
    if (isError) {
      setOpenAlert(true)
      setTypeAlert('error')
      setMessageAlert(error?.data?.meta?.message)
    }
  }, [isError, error])

  return (
    <>
      <Modal
        keepMounted
        open={open}
        onClose={handleClose}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={style} className="absolute top-2/4	left-1/2 bg-white pb-6">
          <div className="flex justify-center font-bold text-[20px] mb-1 px-5 pt-3">
            Những người đặt đồ
          </div>
          <div className="px-5 pt-5 space-y-2.5">
            {data?.result.length > 0 ? (
              data?.result?.map((item) => {
                return (
                  <ItemPeopleBook
                    data={item}
                    isPostGiving={isPostGiving}
                    handleAcceptBook={(value) => handleAcceptBook(value)}
                    isLoading={isLoading}
                  />
                )
              })
            ) : (
              <Typography variant="h6" textAlign="center">
                Hiện tại không có ai đặt đồ!
              </Typography>
            )}
          </div>
        </Box>
      </Modal>
      <MyAlert
        open={openAlert}
        type={typeAlert}
        message={messageAlert}
        handleClose={() => setOpenAlert(false)}
      />
    </>
  )
}

export default ModalPeopleBook
