import { useMemo, useState } from 'react'
import { POST_TYPE } from '../../../constant'
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material'
import ContentAtSchool from '../ContentAtSchool/ContentAtSchool'
import ContentAtLocation from '../ContentAtLocation/ContentAtLocation'

const FormTypePost = ({ handleGetDataTypeGet, errorNotTime, radioType }) => {
  const [valueRadio, setValueRadio] = useState(POST_TYPE.SCHOOL)
  const [dataGetFromSchool, setDataGetFromSchool] = useState({})
  const [dataGetFromAddress, setDataGetFromAddress] = useState({})

  const dataFormAtContent = useMemo(() => {
    if (valueRadio === POST_TYPE.SCHOOL) {
      return {
        dataGetFromSchool,
        valueRadio,
      }
    } else if (valueRadio === POST_TYPE.ADDRESS) {
      return {
        dataGetFromAddress,
        valueRadio,
      }
    }
  }, [valueRadio, dataGetFromSchool, dataGetFromAddress])

  const handleChangeRadio = (event) => {
    setValueRadio(event.target.value)
  }

  const renderContentToggleRadio = () => {
    if (valueRadio === POST_TYPE.SCHOOL) {
      return (
        <ContentAtSchool
          handleGetBySchool={(dataBySchool) => setDataGetFromSchool(dataBySchool)}
          errorNotTime={errorNotTime}
        />
      )
    } else if (valueRadio === POST_TYPE.ADDRESS) {
      return (
        <ContentAtLocation
          handleGet={(data) => {
            setDataGetFromAddress(data)
          }}
        />
      )
    }
  }

  handleGetDataTypeGet(dataFormAtContent)
  radioType(valueRadio)

  return (
    <>
      <FormControl>
        <FormLabel id="demo-radio-buttons-group-label">Chọn cách thức nhận đồ</FormLabel>
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          defaultValue={POST_TYPE.SCHOOL}
          name="radio-buttons-group"
          onChange={handleChangeRadio}
        >
          <FormControlLabel value={POST_TYPE.SCHOOL} control={<Radio />} label="Tại trường" />
          <FormControlLabel value={POST_TYPE.ADDRESS} control={<Radio />} label="Địa chỉ khác" />
        </RadioGroup>
      </FormControl>
      <div>{renderContentToggleRadio()}</div>
    </>
  )
}

export default FormTypePost
