import { Button, InputBase, Paper } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import OpenInBrowserIcon from '@mui/icons-material/OpenInBrowser'
import React, { useState } from 'react'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import bannerRadius from '../../assets/image/hvit-topp.png'
import ModalLogin from '../../components/UI/Popup/ModalLogin'
import { Link } from 'react-router-dom'
import SearchBarLandingPage from '../../components/UI/Search/SearchBarLandingPage'

const SectionHome = ({}) => {
  const [open, setOpen] = useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  return (
    <>
      <div className="mb-28">
        <div className="info-website relative">
          <div className="left-infor relative z-20 pt-36">
            <div className="title-home mb-9">
              <h1 className="uppercase text-h1-home mb-5">
                <span className="text-transparent text-center-empty">SGU</span>
                <br />
                Exchange
              </h1>
              <p className="text-des-home text-3xl">Cho đi để được nhận lại</p>
            </div>
            <div className="form-home max-w-2xl">
              <SearchBarLandingPage className={'w-[665px] max-h-[500px]'} />
              <div className="button-link flex mt-10">
                <div className="mr-5">
                  <Link to="/home">
                    <Button
                      sx={{ padding: '13px 20px', borderRadius: '10px', fontSize: '16px' }}
                      color="secondary"
                      variant="contained"
                      startIcon={<OpenInBrowserIcon />}
                    >
                      Trang chủ
                    </Button>
                  </Link>
                </div>
                <div>
                  <Button
                    sx={{ padding: '13px 20px', borderRadius: '10px', fontSize: '16px' }}
                    variant="contained"
                    startIcon={<AddCircleOutlineIcon />}
                    onClick={handleOpen}
                  >
                    Tạo trao đổi
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div className="right-img absolute top-0 z-10">
            <div className="img-radius">
              <img src={bannerRadius} alt="" />
            </div>
            <div className="logo-banner absolute top-10 w-[60px]">
              <img
                className="rounded-full"
                src="https://scontent.fsgn5-13.fna.fbcdn.net/v/t39.30808-6/326551677_3862437617316104_6630928924698442445_n.jpg?_nc_cat=101&ccb=1-7&_nc_sid=09cbfe&_nc_ohc=8f6dWJEv0agAX8pxecE&_nc_ht=scontent.fsgn5-13.fna&oh=00_AfA6f4zFFDQyRMtRDdrOxGHYfKwMtJ6vsthKq_5nABIfnw&oe=646FFF16"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
      <div className="introduce mb-11">
        <div className="title-home mb-9">
          <h1 className="uppercase text-h1-home text-center" style={{ fontSize: '2rem' }}>
            <span className="text-transparent text-center-empty">Mục Tiêu </span>
            Exchange
          </h1>
        </div>
        <p className="text-des-home m-auto text-center">
          Chúng tôi tin rằng trao đổi đồ cũ là chìa khóa quan trọng giúp thế giới bền vững hơn. Tại
          Exchange Sgu, chúng tôi giúp mọi người #tái sử dụng bằng cách làm cho đồ cũ trở nên thú vị
          và truyền cảm hứng hơn.
        </p>
      </div>

      <div className="good">
        <div className="title-home">
          <h1 className="uppercase text-h1-home text-center" style={{ fontSize: '2rem' }}>
            ✔ Một Ngày Tốt Lành ✔
          </h1>
        </div>
      </div>

      <ModalLogin open={open} handleClose={handleClose} />
    </>
  )
}

export default SectionHome
