import { createSlice } from '@reduxjs/toolkit'

const initialState = { token: null, isLogged: false }

const slice = createSlice({
  name: 'authSlice',
  initialState,
  reducers: {
    setCredentials: (state, { payload: { accessToken } }) => {
      state.token = accessToken
      state.isLogged = true
    },
    logout: (state) => {
      state.token = null
      state.isLogged = false
    },
  },
})

export const { setCredentials, logout } = slice.actions

export default slice.reducer
