import { Box, FormControl, Modal, TextField } from '@mui/material'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import MyAlert from '../../../components/UI/Alert/Alert'
import AvatarUpload from '../../../components/UI/AvatarUpload/AvatarUpload'
import CustomButton from '../../../components/UI/CustomButton/CustomButton'
import {
  useGetAccountQuery,
  useGetMeQuery,
  useUpdateInfoProfileMutation,
} from '../../../services/Account/accountAPI'
import { regexPhoneNumber } from '../../../utils/regex'

const style = {
  transform: 'translate(-50%, -50%)',
  width: 800,
  boxShadow: 24,
  borderRadius: '30px',
}

const ModalSettingAccount = ({ open, handleClose }) => {
  const [avatar, setAvatar] = useState('')
  const [phone, setPhone] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [address, setAddress] = useState('')
  const [city, setCity] = useState('')
  const [checkPhone, setCheckPhone] = useState(false)
  const [messageAlert, setMessageAlert] = useState('')
  const [typeAlert, setTypeAlert] = useState('')
  const [openAlert, setOpenAlert] = useState(false)

  const { id_profile } = useParams()

  const [updateInfoProfile, { isLoading, isError, error: errorRegister }] =
    useUpdateInfoProfileMutation()
  const { data: account, refetch: refetchAccount } = useGetAccountQuery(id_profile, {
    refetchOnMountOrArgChange: true,
  })
  const { data: me, refetch: refetchMe } = useGetMeQuery(undefined, {
    refetchOnMountOrArgChange: true,
  })
  const {
    avatar: avatarMe,
    address: addressMe,
    city: cityMe,
    firstName: firstNameMe,
    lastName: lastNameMe,
    phone: phoneMe,
  } = me?.result

  const handleSetting = async () => {
    const checkRegexPhone = regexPhoneNumber(phone)

    if (checkRegexPhone) {
      setCheckPhone(false)
      const data = {
        firstName,
        lastName,
        phone,
        address,
        city,
        avatar:
          'https://as1.ftcdn.net/v2/jpg/02/87/26/36/1000_F_287263612_UCo7Xksztf3xyYus4CYSg4bhsPqup319.jpg',
      }
      const res = await updateInfoProfile(data)

      if (res?.data?.meta?.message === 'Success') {
        setOpenAlert(true)
        setTypeAlert('success')
        setMessageAlert('Sửa thông tin cá nhân thành công')
        handleClose()
        refetchAccount()
        refetchMe()
      }
    } else {
      setCheckPhone(true)
    }
  }

  useEffect(() => {
    if (isError) {
      setOpenAlert(true)
      setTypeAlert('error')
      setMessageAlert(errorRegister?.data?.meta?.message)
    }
  }, [isError, errorRegister])

  useEffect(() => {
    setAvatar(avatarMe)
    setPhone(phoneMe)
    setFirstName(firstNameMe)
    setLastName(lastNameMe)
    setCity(cityMe)
    setAddress(addressMe)
  }, [phoneMe, firstNameMe, lastNameMe, cityMe, addressMe, avatarMe])

  return (
    <>
      <Modal
        keepMounted
        open={open}
        onClose={handleClose}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={style} className="absolute top-2/4	left-1/2 bg-white pb-3">
          <div className="flex justify-center font-bold text-[20px] mb-1 px-5 pt-1">
            Chỉnh sửa thông tin cá nhân
          </div>
          <hr />
          <div className="px-5 pt-5">
            <form>
              <FormControl className="w-full" size="small">
                <div className="space-y-4">
                  <div className="w-full flex justify-center mb-12">
                    <AvatarUpload
                      getDataAvatar={(value) => {
                        setAvatar('value :>> ', value)
                      }}
                    />
                  </div>

                  <div className="flex justify-between">
                    <div className="mr-4 w-1/2">
                      <TextField
                        className="text-color-landing-page"
                        placeholder="Họ"
                        fullWidth
                        size="small"
                        required
                        defaultValue={firstNameMe}
                        onChange={(event) => setFirstName(event.target.value)}
                      />
                    </div>
                    <div className="w-1/2">
                      <TextField
                        className="text-color-landing-page"
                        placeholder="Tên"
                        fullWidth
                        size="small"
                        required
                        defaultValue={lastNameMe}
                        onChange={(event) => setLastName(event.target.value)}
                      />
                    </div>
                  </div>

                  <div className="flex justify-between">
                    <div className="mr-4 w-1/2">
                      <TextField
                        className="text-color-landing-page"
                        placeholder="Số điện thoại"
                        fullWidth
                        size="small"
                        required
                        defaultValue={phoneMe}
                        onChange={(event) => setPhone(event.target.value)}
                      />
                    </div>
                    <div className="w-1/2">
                      <TextField
                        className="text-color-landing-page"
                        placeholder="Thành phố"
                        fullWidth
                        size="small"
                        required
                        defaultValue={cityMe}
                        onChange={(event) => setCity(event.target.value)}
                      />
                    </div>
                  </div>
                  <div className="w-full">
                    <TextField
                      className="text-color-landing-page"
                      placeholder="Đại chỉ"
                      fullWidth
                      size="small"
                      required
                      defaultValue={addressMe}
                      onChange={(event) => setAddress(event.target.value)}
                    />
                  </div>
                </div>

                <div className="flex justify-center items-center flex-col mt-5">
                  {checkPhone && phone && (
                    <p className="mb-4 text-rose-600">Số điện thoại sai định dạng</p>
                  )}

                  <div>
                    <CustomButton
                      // type="submit"
                      name="Xác nhận"
                      size="small"
                      color="primary"
                      isLoading={isLoading}
                      onClick={handleSetting}
                    />
                  </div>
                </div>
              </FormControl>
            </form>
          </div>
        </Box>
      </Modal>
      <MyAlert
        open={openAlert}
        type={typeAlert}
        message={messageAlert}
        handleClose={() => setOpenAlert(false)}
      />
    </>
  )
}

export default ModalSettingAccount
