import SearchIcon from '@mui/icons-material/Search'
import { Avatar, InputBase, List, Paper } from '@mui/material'
import { styled } from '@mui/material/styles'
import React, { useState } from 'react'
import AvatarDefault from '../../../assets/image/avatar-default.jpeg'
import { useGetListPostQuery } from '../../../services/Post/postAPI'
import { Link } from 'react-router-dom'

const SearchContainer = styled('div')({})

const StyledList = styled(List)({
  position: 'absolute',
  top: '85%',
  left: '2%',
  backgroundColor: '#fff',
  borderRadius: '10px',
  padding: 10,
  boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.05)',
  zIndex: 2,
  marginLeft: '-13px',
  overflow: 'auto',
})

const Backdrop = styled('div')({
  position: 'absolute',
  top: '70px',
  left: 0,
  height: '100%',
  width: '100%',
  backgroundColor: 'rgba(0,0,0,0.4)',
  zIndex: 1,
})

const ItemPostSearch = ({ name, address, avatar }) => {
  return (
    <div>
      <div className="p-3 flex justify-between">
        <div className="flex">
          <div className="flex items-center">
            <Avatar alt="avatar" src={avatar || AvatarDefault} className="mr-2" />
          </div>
          <div>
            <span className="text-[14px] font-semibold">{name}</span>
            <p className="text-[14px]">{address}</p>
          </div>
        </div>
      </div>
    </div>
  )
}

const SearchBarLandingPage = ({ className }) => {
  const [searchTerm, setSearchTerm] = useState('')
  const [showDropdown, setShowDropdown] = useState(false)

  const handleSearchInput = (event) => {
    setSearchTerm(event.target.value)
  }

  const { data } = useGetListPostQuery(
    { title: searchTerm },
    {
      refetchOnMountOrArgChange: true,
    }
  )

  const handleInputFocus = () => {
    setShowDropdown(true)
  }

  const handleInputBlur = (event) => {
    event.preventDefault()

    setTimeout(() => {
      setShowDropdown(false)
    }, 250)
  }

  return (
    <SearchContainer>
      <Paper
        className="relative mb-5"
        component="form"
        sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', borderRadius: '20px' }}
      >
        <SearchIcon fontSize="large" className="absolute left-9 top-9" />
        <InputBase
          className="py-7 pr-3.5 pl-24 text-color-landing-page"
          sx={{ ml: 1, flex: 1, fontSize: '25px' }}
          placeholder="Bạn đang cần tìm kiếm gì?"
          value={searchTerm}
          onChange={handleSearchInput}
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
        />
      </Paper>
      {showDropdown && (
        <>
          <StyledList className={className}>
            {data?.result?.posts.length > 0 ? (
              (data?.result?.posts || []).map((post) => {
                return (
                  <Link to={`/post/${post?.id}`}>
                    <ItemPostSearch
                      name={post?.title}
                      address={post?.account?.address}
                      avatar={post?.imagesPost[0]?.url}
                    />
                  </Link>
                )
              })
            ) : (
              <div>Không tìm thấy bài đăng nào</div>
            )}
          </StyledList>
        </>
      )}
    </SearchContainer>
  )
}

export default SearchBarLandingPage
